// // import React, { useState } from "react";
// // import { motion, AnimatePresence } from "framer-motion";
// // import { LeftOutlined, RightOutlined } from "@ant-design/icons";

// // // Import images
// // import ArchitectImage1 from "../../assets/PNG/Brix/Slide1.png";
// // import ArchitectImage2 from "../../assets/PNG/Brix/Slide2.png";
// // import ArchitectImage3 from "../../assets/PNG/Brix/Slide3.png";
// // import ArchitectImage4 from "../../assets/PNG/Brix/Slide4.png";

// // const ArchitectsAndDesigners = () => {
// //   const slides = [
// //     {
// //       heading: "BRIX for Architects & Designers",
// //       points: [
// //         "Streamlined platform to manage complex design files, blueprints, and project documentation",
// //         "Real-time collaboration with team members and clients, ensuring everyone works with the latest versions",
// //         "Supports large file formats, including CAD and 3D models",
// //         "Easy organization, sharing, and tracking of revisions across multiple stakeholders",
// //         "Advanced version control for managing design iterations efficiently",
// //         "Secure storage and access to all design assets from any location, at any time",
// //       ],
// //       image: ArchitectImage1,
// //     },
// //     {
// //       heading: "BRIX for Construction Companies & Contractors",
// //       points: [
// //         "Comprehensive platform tailored for managing construction documents and workflows",
// //         "Seamless collaboration between field teams, office staff, and subcontractors",
// //         "Supports large files like blueprints, contracts, and project schedules",
// //         "Automates document tracking, approvals, and version control to reduce delays",
// //         "Ensures secure access to critical project files, anytime, from any location",
// //         "Improves communication and project transparency across all stakeholders",
// //       ],
// //       image: ArchitectImage2,
// //     },
// //     {
// //       heading: "BRIX for Real Estate Brokers & Developers",
// //       points: [
// //         "Centralized platform to manage property documents, contracts, and project plans",
// //         "Streamlined collaboration between brokers, developers, and buyers in real-time",
// //         "Easy access to legal documents, property listings, and sales agreements from any device",
// //         "Advanced version control to track changes and updates to contracts and property documents",
// //         "Facilitates faster decision-making by providing instant access to critical information",
// //         "Automates document workflows, reducing paperwork and speeding up transaction processes",
// //       ],
// //       image: ArchitectImage3,
// //     },
// //     {
// //       heading: "BRIX for Project Managers",
// //       points: [
// //         "Tailored platform to manage project documents, workflows, and timelines",
// //         "Streamlined collaboration between team members, tools, and external stakeholders",
// //         "Centralized storage for project-related documents and data",
// //         "Improved communication and accountability across all stakeholders",
// //         "Version control for managing design iterations and tracking document updates",
// //         "Advanced security to ensure sensitive information remains protected",
// //       ],
// //       image: ArchitectImage4,
// //     },
// //   ];

// //   const [currentIndex, setCurrentIndex] = useState(0);
// //   const [direction, setDirection] = useState(0);

// //   const handleNext = () => {
// //     setDirection(1);
// //     setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
// //   };

// //   const handlePrev = () => {
// //     setDirection(-1);
// //     setCurrentIndex((prevIndex) =>
// //       prevIndex === 0 ? slides.length - 1 : prevIndex - 1
// //     );
// //   };

// //   const variants = {
// //     enter: (direction) => ({
// //       x: direction > 0 ? 300 : -300,
// //       opacity: 0,
// //     }),
// //     center: {
// //       x: 0,
// //       opacity: 1,
// //     },
// //     exit: (direction) => ({
// //       x: direction > 0 ? -300 : 300,
// //       opacity: 0,
// //     }),
// //   };

// //   return (
// //     <div
// //       className="flex flex-col items-center py-8 sm:py-12 px-4"
// //       style={{
// //         background: "linear-gradient(to right, #E3F9F2 30%, #FFEBE8 90%)",
// //       }}
// //     >
// //       <div className="relative w-full max-w-[1280px] h-[400px] sm:h-[500px] rounded-[20px] border-[3px] border-[#D1F8F0] shadow-lg overflow-hidden">
// //         <AnimatePresence initial={false} custom={direction}>
// //           <motion.div
// //             key={slides[currentIndex].heading}
// //             className="absolute inset-0 flex flex-col md:flex-row w-full h-full bg-white"
// //             variants={variants}
// //             initial="enter"
// //             animate="center"
// //             exit="exit"
// //             custom={direction}
// //             transition={{ duration: 0.5 }}
// //           >
// //             {/* Left: Content */}
// //             <div className="flex flex-col w-full md:w-1/2 p-6 sm:p-10">
// //               <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-[#1B2028] mb-4">
// //                 {slides[currentIndex].heading}
// //               </h2>
// //               <hr className="border-t-4 border-[#4ED2BF] w-full mb-4 sm:mb-6" />
// //               <ul className="list-disc list-inside space-y-2 sm:space-y-4 text-[#59616E]">
// //                 {slides[currentIndex].points.map((point, i) => (
// //                   <li key={i} className="leading-relaxed text-sm sm:text-base">
// //                     {point}
// //                   </li>
// //                 ))}
// //               </ul>
// //             </div>

// //             {/* Right: Image */}
// //             <div
// //               className="flex w-full md:w-1/2 h-[200px] sm:h-[300px] md:h-full bg-cover bg-center rounded-b-[20px] md:rounded-r-[20px] md:rounded-bl-none"
// //               style={{
// //                 backgroundImage: `url(${slides[currentIndex].image})`,
// //               }}
// //             ></div>
// //           </motion.div>
// //         </AnimatePresence>
// //       </div>

// //       {/* Bottom Pagination Controls */}
// //       <div className="flex w-[160px] sm:w-[240px] justify-between items-center mt-6">
// //         <button
// //           onClick={handlePrev}
// //           className="bg-[#FF6F61] w-10 h-10 sm:w-12 sm:h-12 flex items-center justify-center rounded-full shadow-md"
// //         >
// //           <LeftOutlined style={{ color: "#FFFFFF", fontSize: "16px" }} />
// //         </button>
// //         <button
// //           onClick={handleNext}
// //           className="bg-[#FF6F61] w-10 h-10 sm:w-12 sm:h-12 flex items-center justify-center rounded-full shadow-md"
// //         >
// //           <RightOutlined style={{ color: "#FFFFFF", fontSize: "16px" }} />
// //         </button>
// //       </div>
// //     </div>
// //   );
// // };

// // export default ArchitectsAndDesigners;












// import React, { useState } from "react";
// import { motion, AnimatePresence } from "framer-motion";
// import { LeftOutlined, RightOutlined } from "@ant-design/icons";

// // Import images
// import ArchitectImage1 from "../../assets/PNG/Brix/Slide1.png";
// import ArchitectImage2 from "../../assets/PNG/Brix/Slide2.png";
// import ArchitectImage3 from "../../assets/PNG/Brix/Slide3.png";
// import ArchitectImage4 from "../../assets/PNG/Brix/Slide4.png";

// const ArchitectsAndDesigners = () => {
//   const slides = [
//     {
//       heading: "BRIX for Architects & Designers",
//       points: [
//         "Streamlined platform to manage complex design files, blueprints, and project documentation",
//         "Real-time collaboration with team members and clients, ensuring everyone works with the latest versions",
//         "Supports large file formats, including CAD and 3D models",
//         "Easy organization, sharing, and tracking of revisions across multiple stakeholders",
//         "Advanced version control for managing design iterations efficiently",
//         "Secure storage and access to all design assets from any location, at any time",
//       ],
//       image: ArchitectImage1,
//     },
//     {
//       heading: "BRIX for Construction Companies & Contractors",
//       points: [
//         "Comprehensive platform tailored for managing construction documents and workflows",
//         "Seamless collaboration between field teams, office staff, and subcontractors",
//         "Supports large files like blueprints, contracts, and project schedules",
//         "Automates document tracking, approvals, and version control to reduce delays",
//         "Ensures secure access to critical project files, anytime, from any location",
//         "Improves communication and project transparency across all stakeholders",
//       ],
//       image: ArchitectImage2,
//     },
//     {
//       heading: "BRIX for Real Estate Brokers & Developers",
//       points: [
//         "Centralized platform to manage property documents, contracts, and project plans",
//         "Streamlined collaboration between brokers, developers, and buyers in real-time",
//         "Easy access to legal documents, property listings, and sales agreements from any device",
//         "Advanced version control to track changes and updates to contracts and property documents",
//         "Facilitates faster decision-making by providing instant access to critical information",
//         "Automates document workflows, reducing paperwork.",
//       ],
//       image: ArchitectImage3,
//     },
//     {
//       heading: "BRIX for Project Managers",
//       points: [
//         "Tailored platform to manage project documents, workflows, and timelines",
//         "Streamlined collaboration between team members, tools, and external stakeholders",
//         "Centralized storage for project-related documents and data",
//         "Improved communication and accountability across all stakeholders",
//         "Version control for managing design iterations and tracking document updates",
//         "Advanced security to ensure sensitive information remains protected",
//       ],
//       image: ArchitectImage4,
//     },
//   ];

//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [direction, setDirection] = useState(0);

//   const handleNext = () => {
//     setDirection(1);
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
//   };

//   const handlePrev = () => {
//     setDirection(-1);
//     setCurrentIndex((prevIndex) =>
//       prevIndex === 0 ? slides.length - 1 : prevIndex - 1
//     );
//   };

//   const variants = {
//     enter: (direction) => ({
//       x: direction > 0 ? 300 : -300,
//       opacity: 0,
//     }),
//     center: {
//       x: 0,
//       opacity: 1,
//     },
//     exit: (direction) => ({
//       x: direction > 0 ? -300 : 300,
//       opacity: 0,
//     }),
//   };

//   return (
//     <div
//       className="flex flex-col items-center py-8 sm:py-12 px-4"
//       style={{
//         background: "linear-gradient(to right, #E3F9F2 30%, #FFEBE8 90%)",
//       }}
//     >
//       <div className="relative w-full max-w-[1280px] h-[400px] sm:h-[515px] rounded-[20px] border-[3px] border-[#D1F8F0] shadow-lg overflow-hidden">
//         <AnimatePresence initial={false} custom={direction}>
//           <motion.div
//             key={slides[currentIndex].heading}
//             className="absolute inset-0 flex flex-col md:flex-row w-full h-full bg-white"
//             variants={variants}
//             initial="enter"
//             animate="center"
//             exit="exit"
//             custom={direction}
//             transition={{ duration: 0.5 }}
//           >
//             {/* Left: Content */}
// <div className="flex flex-col w-full md:w-1/2 p-6 sm:p-12">
//   <h2 className="text-xl sm:text-2xl md:text-2xl font-bold text-[#1B2028] mb-3 sm:mb-4">
//     {slides[currentIndex].heading}
//   </h2>
//   <hr className="border-t-4 border-[#4ED2BF] w-[100%] mt-1" />
//   <ul className="list-disc list-inside space-y-6 sm:space-y-6 text-[#59616E] mb-6 sm:mb-6">
//     {slides[currentIndex].points.map((point, i) => (
//       <li key={i} className="leading-relaxed text-sm sm:text-base">
//         {point}
//       </li>
//     ))}
//   </ul>
// </div>

//             {/* Right: Image */}
//             <div
//               className="flex w-full md:w-1/2 h-[200px] sm:h-[300px] md:h-full bg-cover bg-center rounded-b-[20px] md:rounded-r-[20px] md:rounded-bl-none"
//               style={{
//                 backgroundImage: `url(${slides[currentIndex].image})`,
//               }}
//             ></div>
//           </motion.div>
//         </AnimatePresence>
//       </div>

//       {/* Bottom Pagination Controls */}
//       <div className="flex w-[160px] sm:w-[240px] justify-between items-center mt-6">
//         <button
//           onClick={handlePrev}
//           className="bg-[#FF6F61] w-10 h-10 sm:w-12 sm:h-12 flex items-center justify-center rounded-full shadow-md"
//         >
//           <LeftOutlined style={{ color: "#FFFFFF", fontSize: "16px" }} />
//         </button>
//         <button
//           onClick={handleNext}
//           className="bg-[#FF6F61] w-10 h-10 sm:w-12 sm:h-12 flex items-center justify-center rounded-full shadow-md"
//         >
//           <RightOutlined style={{ color: "#FFFFFF", fontSize: "16px" }} />
//         </button>
//       </div>
//     </div>
//   );
// };

// export default ArchitectsAndDesigners;




import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

// Import images
import ArchitectImage1 from "../../assets/PNG/Brix/Slide1.png";
import ArchitectImage2 from "../../assets/PNG/Brix/Slide2.png";
import ArchitectImage3 from "../../assets/PNG/Brix/Slide3.png";
import ArchitectImage4 from "../../assets/PNG/Brix/Slide4.png";

const ArchitectsAndDesigners = () => {
  const slides = [
    {
      heading: "BRIX for Architects & Designers",
      points: [
        "Streamlined platform to manage complex design files, blueprints, and project documentation",
        "Real-time collaboration with team members and clients, ensuring everyone works with the latest versions",
        "Supports large file formats, including CAD and 3D models",
        "Easy organization, sharing, and tracking of revisions across multiple stakeholders",
        "Advanced version control for managing design iterations efficiently",
        "Secure storage and access to all design assets from any location, at any time",
      ],
      image: ArchitectImage1,
    },
    {
      heading: "BRIX for Construction Companies & Contractors",
      points: [
        "Comprehensive platform tailored for managing construction documents and workflows",
        "Seamless collaboration between field teams, office staff, and subcontractors",
        "Supports large files like blueprints, contracts, and project schedules",
        "Automates document tracking, approvals, and version control to reduce delays",
        "Ensures secure access to critical project files, anytime, from any location",
        "Improves communication and project transparency across all stakeholders",
      ],
      image: ArchitectImage2,
    },
    {
      heading: "BRIX for Real Estate Brokers & Developers",
      points: [
        "Centralized platform to manage property documents, contracts, and project plans",
        "Streamlined collaboration between brokers, developers, and buyers in real-time",
        "Easy access to legal documents, property listings, and sales agreements from any device",
        "Advanced version control to track changes and updates to contracts and property documents",
        "Facilitates faster decision-making by providing instant access to critical information",
        "Automates document workflows, reducing paperwork.",
      ],
      image: ArchitectImage3,
    },
    {
      heading: "BRIX for Project Managers",
      points: [
        "Tailored platform to manage project documents, workflows, and timelines",
        "Streamlined collaboration between team members, tools, and external stakeholders",
        "Centralized storage for project-related documents and data",
        "Improved communication and accountability across all stakeholders",
        "Version control for managing design iterations and tracking document updates",
        "Advanced security to ensure sensitive information remains protected",
      ],
      image: ArchitectImage4,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNext = () => {
    setDirection(1);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const handlePrev = () => {
    setDirection(-1);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  const variants = {
    enter: (direction) => (isMobile ? { opacity: 0, x: direction > 0 ? 300 : -300 } : {}),
    center: { x: 0, opacity: 1 },
    exit: (direction) => (isMobile ? { opacity: 0, x: direction > 0 ? -300 : 300 } : {}),
  };

  return (
    <div
      className={`flex flex-col items-center ${
        isMobile ? "py-4 px-4" : "py-8 sm:py-12 px-4"
      }`}
      style={{
        background: "linear-gradient(to right, #E3F9F2 30%, #FFEBE8 90%)",
      }}
    >
      <div
        className={`relative w-full ${
          isMobile ? "max-w-[573px] h-[733px]" : "max-w-[1280px] h-[400px] sm:h-[515px]"
        } rounded-[20px] shadow-lg overflow-hidden`}
      >
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            key={slides[currentIndex].heading}
            className={`absolute inset-0 flex ${
              isMobile ? "flex-col" : "flex-col md:flex-row"
            } w-full h-full bg-white`}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            custom={direction}
            transition={{ duration: 0.5 }}
          >
            {/* Left: Content */}
            <div
              className={`flex flex-col ${
                isMobile ? "w-full p-4" : "w-full md:w-1/2 p-6 sm:p-12"
              }`}
            >
              <h2
                className={`${
                  isMobile ? "text-lg mb-3" : "text-xl sm:text-2xl md:text-2xl mb-4"
                } font-bold text-[#1B2028]`}
              >
                {slides[currentIndex].heading}
              </h2>
              <hr className="border-t-4 border-[#4ED2BF] w-full mt-1 mb-2" />
              <ul
                className={`${
                  isMobile ? "list-disc list-inside space-y-3" : "list-disc list-inside space-y-6"
                } text-[#59616E]`}
              >
                {slides[currentIndex].points.map((point, i) => (
                  <li key={i} className="leading-relaxed text-sm">
                    {point}
                  </li>
                ))}
              </ul>
            </div>

            {/* Right: Image */}
            <div
              className={`flex ${
                isMobile ? "h-[330px]" : "w-full md:w-1/2 h-[300px] md:h-full"
              } bg-cover bg-center rounded-b-[20px] md:rounded-r-[20px] md:rounded-bl-none`}
              style={{
                backgroundImage: `url(${slides[currentIndex].image})`,
              }}
            ></div>
          </motion.div>
        </AnimatePresence>
      </div>

      {/* Bottom Pagination Controls */}
      <div
        className={`flex ${
          isMobile ? "w-[140px] mt-4" : "w-[160px] sm:w-[240px] mt-6"
        } justify-between items-center`}
      >
        <button
          onClick={handlePrev}
          className={`bg-[#FF6F61] ${
            isMobile ? "w-8 h-8" : "w-10 h-10 sm:w-12 sm:h-12"
          } flex items-center justify-center rounded-full shadow-md`}
        >
          <LeftOutlined style={{ color: "#FFFFFF", fontSize: isMobile ? "14px" : "16px" }} />
        </button>
        <button
          onClick={handleNext}
          className={`bg-[#FF6F61] ${
            isMobile ? "w-8 h-8" : "w-10 h-10 sm:w-12 sm:h-12"
          } flex items-center justify-center rounded-full shadow-md`}
        >
          <RightOutlined style={{ color: "#FFFFFF", fontSize: isMobile ? "14px" : "16px" }} />
        </button>
      </div>
    </div>
  );
};

export default ArchitectsAndDesigners;


