import React, { useState, useEffect } from "react";
import Logo from "../../assets/PNG/Brix/Brix-Logo.png"; // Replace with the correct path to your logo

const ProductDetails = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={`flex flex-col ${
        isMobile ? "px-4 py-8" : "md:flex-row px-12 py-16"
      }`}
      style={{
        flexShrink: 0,
        background: "#11222C",
      }}
    >
      {/* Left Side: Logo and Product Description */}
      <div
        className={`w-full ${
          isMobile ? "mb-8 flex flex-col items-start" : "md:w-1/2 mb-0"
        }`}
      >
        {/* Logo */}
        <img
          src={Logo}
          alt="Brix Logo"
          className={`${
            isMobile ? "w-[100px] mb-4" : "w-[125px] mb-6"
          } h-auto flex-shrink-0`}
        />

        {/* Product Description */}
        <p
          className={`text-[14px] font-light leading-relaxed ${
            isMobile ? "text-left" : "text-left"
          } text-white/70 font-roboto`}
        >
          Brix is a cloud-based document management system designed specifically
          for the infrastructure and construction industries. It simplifies the
          process of digitizing, storing, and organizing documents, providing an
          intuitive experience for users with varying levels of technological
          expertise.
        </p>
      </div>

      {/* Right Side: Features, Page, and Pricing */}
      {isMobile ? (
        <div className="w-full flex flex-col items-start space-y-6">
          {/* Features Section */}
          <div className="flex flex-col items-start w-full">
            <h3 className="text-[16px] font-bold text-[#FFFFFF99] font-roboto">
              Features
            </h3>
            <ul className="pl-0 mt-1 text-[14px] text-white/70 font-roboto">
              <li className="mb-1">Security & Encryption</li>
              <li className="mb-1">Personal Vault</li>
              <li className="mb-1">Personal Task Management</li>
              <li className="mb-1">Version Control</li>
            </ul>
          </div>

          {/* Page Section */}
          <div className="flex flex-col items-start w-full">
            <h3 className="text-[16px] font-bold text-[#FFFFFF99] font-roboto">
              Page
            </h3>
            <ul className="pl-0 mt-1 text-[14px] text-white/70 font-roboto">
              <li className="mb-1">Blog</li>
              <li className="mb-1">Terms & Conditions</li>
            </ul>
          </div>

          {/* Pricing Section */}
          <div className="flex flex-col items-start w-full">
          <h3 className="text-[16px] font-bold text-[#FFFFFF99] font-roboto">
          Pricing
            </h3>
            <ul className="pl-0 mt-1 text-[14px] text-white/70 font-roboto">
              <li className="mb-1">Subscription</li>
            </ul>
          </div>
        </div>
      ) : (
        // Desktop Code: Completely Unchanged
        <div className="w-full md:w-1/2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 md:gap-x-12 gap-y-6">
          {/* Features Section */}
          <div className="flex flex-col items-center">
            <h3 className="text-[14px] sm:text-[16px] font-bold text-[#FFFFFF99] font-roboto mb-4 text-center">
              Features
            </h3>
            <ul className="space-y-2 sm:space-y-3">
              <li className="text-[12px] sm:text-[14px] text-[#FFFFFF80] font-roboto text-center">
                Security & Encryption
              </li>
              <li className="text-[12px] sm:text-[14px] text-[#FFFFFF80] font-roboto text-center">
                Secret Vault
              </li>
              <li className="text-[12px] sm:text-[14px] text-[#FFFFFF80] font-roboto text-center">
                Task Management
              </li>
              <li className="text-[12px] sm:text-[14px] text-[#FFFFFF80] font-roboto text-center">
                Version Control
              </li>
              <li className="text-[12px] sm:text-[14px] text-[#FFFFFF80] font-roboto text-center">
                Robust and Secure Onboarding
              </li>
            </ul>
          </div>

          {/* Page Section */}
          <div className="flex flex-col items-center">
            <h3 className="text-[14px] sm:text-[16px] font-bold text-[#FFFFFF99] font-roboto mb-4 text-center">
              Page
            </h3>
            <ul className="space-y-2 sm:space-y-3">
              <li className="text-[12px] sm:text-[14px] text-[#FFFFFF80] font-roboto text-center">
                Blog
              </li>
              <li className="text-[12px] sm:text-[14px] text-[#FFFFFF80] font-roboto text-center">
                Terms & Conditions
              </li>
            </ul>
          </div>

          {/* Pricing Section */}
          <div className="flex flex-col items-center">
            <h3 className="text-[14px] sm:text-[16px] font-bold text-[#FFFFFF99] font-roboto mb-4 text-center">
              Pricing
            </h3>
            <ul className="space-y-2 sm:space-y-3">
              <li className="text-[12px] sm:text-[14px] text-[#FFFFFF80] font-roboto text-center">
                Subscription
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
