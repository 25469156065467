// import React, { useState } from "react";
// import { Spin } from "antd";
// import axios from "axios";

// const DocumentManagement = () => {
//   const [email, setEmail] = useState("");
//   const [error, setError] = useState("");
//   const [success, setSuccess] = useState("");
//   const [isLoading, setIsLoading] = useState(false);

//   // Function to validate email format
//   const validateEmail = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email format validation
//     return emailRegex.test(email);
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     setIsLoading(true);
//     e.preventDefault();

//     if (!validateEmail(email)) {
//       setError("Please enter a valid email address.");
//       setSuccess("");
//       setIsLoading(false); // Stop loading on validation error
//       return;
//     }

//     setError("");
//     setSuccess("");

//     const data = JSON.stringify({ email, position: "top" });
//     const config = {
//       method: "post",
//       maxBodyLength: Infinity,
//       url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/brix/subscribe`,
//       headers: {
//         "Content-Type": "application/json",
//       },
//       data: data,
//     };

//     try {
//       const response = await axios.request(config); // Send API request
//       setSuccess("Subscription successful!"); // Handle success case
//       setEmail(""); // Clear only the email input
//     } catch (err) {
//       if (err.response && err.response.status === 400) {
//         setSuccess("We Already Have Your Email, Stay Tuned.");
//       } else {
//         setError("Please try again later.");
//       }
//     } finally {
//       setIsLoading(false); // Hide loader
//     }
//   };

//   return (
//     <div className="max-w-[2040px] max-h-[279px] flex-shrink-0 bg-[#11222C] text-white flex flex-col md:flex-row items-center md:justify-between px-6 sm:px-8 lg:px-12 py-8 md:py-12">

//       {/* Left Side: Text */}
//       <div className="flex flex-col space-y-3 sm:space-y-4 text-center md:text-left">
//         <h2 className="text-[20px] sm:text-[24px] md:text-[28px] font-semibold leading-snug md:leading-[121.204%]">
//           Ready to transform your <br /> Document Management?
//         </h2>
//         <p className="text-[12px] sm:text-[14px] font-normal">
//           Sign up for a free trial today!
//         </p>
//       </div>

//       {/* Right Side: Form */}
//       <div className="flex flex-col items-center justify-center mt-6 md:mt-0 w-full md:w-auto">
//         <form
//           className="flex items-center justify-center w-full max-w-[400px] bg-white border border-gray-300 rounded-lg overflow-hidden"
//           onSubmit={handleSubmit}
//         >
//           <input
//             type="email"
//             placeholder="Email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             className="flex-grow px-4 py-3 text-black placeholder-gray-500 focus:outline-none rounded-l-lg"
//             style={{ height: "48px" }} // Match button height
//           />
//           <button
//             type="submit"
//             disabled={isLoading} // Disable button when loading
//             className={`w-[200px] min-w-[200px] px-6 sm:px-8 font-semibold text-white bg-[#FF6F61] transition duration-300 flex items-center justify-center whitespace-nowrap rounded-r-lg ${isLoading ? "cursor-not-allowed" : ""
//               }`}
//             style={{ height: "48px" }} // Ensure button and input match in height
//           >
//             {isLoading ? <Spin size="small" /> : "Claim Free Access"}
//           </button>

//         </form>

//         {/* Success Message */}
//         {success && <p className="text-green-500 text-md font-light mt-4">{success}</p>}

//         {/* Error Message */}
//         {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
//       </div>
//     </div>
//   );
// };

// export default DocumentManagement;







import React, { useState } from "react";
import { Spin } from "antd";
import axios from "axios";

const DocumentManagement = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Function to validate email format
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      setSuccess("");
      setIsLoading(false);
      return;
    }

    setError("");
    setSuccess("");

    const data = JSON.stringify({ email, position: "top" });
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/brix/subscribe`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      setSuccess("Subscription successful!");
      setEmail("");
    } catch (err) {
      if (err.response && err.response.status === 400) {
        setSuccess("We Already Have Your Email, Stay Tuned.");
      } else {
        setError("Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-[2040px] bg-[#11222C] text-white flex flex-col md:flex-row items-center md:justify-between px-4 sm:px-6 lg:px-12 py-8 md:py-12">
      {/* Left Side: Text */}
      <div className="flex flex-col space-y-3 sm:space-y-4 text-center md:text-left">
        <h2 className="text-[18px] sm:text-[22px] md:text-[28px] font-semibold leading-snug md:leading-[121.204%]">
          Ready to transform your <br /> Document Management?
        </h2>
        <p className="text-[12px] sm:text-[14px] font-normal">
          Sign up for a free trial today!
        </p>
      </div>

      {/* Right Side: Form */}
      <div className="flex flex-col items-center justify-center mt-6 md:mt-0 w-full md:w-auto">
        <form
          className="flex items-center justify-between w-full max-w-[400px] bg-white border border-gray-300 rounded-lg"
          onSubmit={handleSubmit}
        >
          {/* Email Input */}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="flex-grow px-4 py-3 text-black placeholder-gray-500 focus:outline-none rounded-l-lg"
            style={{ height: "48px", minWidth: "0" }}
          />

          {/* Submit Button */}
          <button
            type="submit"
            disabled={isLoading}
            className={`flex items-center justify-center font-semibold text-white bg-[#FF6F61] transition duration-300 whitespace-nowrap rounded-r-lg ${
              isLoading ? "cursor-not-allowed" : ""
            }`}
            style={{
              height: "48px",
              width: "200px", // Fixed width for consistency
            }}
          >
            {isLoading ? <Spin size="small" /> : "Claim Free Access"}
          </button>
        </form>

        {/* Success Message */}
        {success && (
          <p className="text-green-500 text-md font-light mt-4">{success}</p>
        )}

        {/* Error Message */}
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      </div>
    </div>
  );
};

export default DocumentManagement;


