// import { useState, useEffect } from "react";
// import { Navbar, Nav, Container } from "react-bootstrap";
// import logo from "../assets/img/logo3.png";
// import { HashLink } from "react-router-hash-link";
// import { BrowserRouter as Router, Link } from "react-router-dom";
// import "./NavBar.css";

// export const NavBar = () => {
//   const [activeLink, setActiveLink] = useState("home");
//   const [scrolled, setScrolled] = useState(false);

//   useEffect(() => {
//     const onScroll = () => {
//       if (window.scrollY > 50) {
//         setScrolled(true);
//       } else {
//         setScrolled(false);
//       }
//     };

//     window.addEventListener("scroll", onScroll);

//     return () => window.removeEventListener("scroll", onScroll);
//   }, []);

//   const onUpdateActiveLink = (value) => {
//     setActiveLink(value);
//   };

//   return (
//     <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
//       <Container>
//         <Navbar.Brand href="/">
//           <img src={logo} alt="PEMS Digital" />
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav">
//           <span className="navbar-toggler-icon"></span>
//         </Navbar.Toggle>
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="ms-auto">
//             <Nav.Link
//               href="/"
//               className={
//                 activeLink === "Home" ? "active navbar-link" : "navbar-link"
//               }
//               onClick={() => onUpdateActiveLink("Home")}
//             >
//               Home
//             </Nav.Link>
//             <Nav.Link
//               href="/Products"
//               className={
//                 activeLink === "Products" ? "active navbar-link" : "navbar-link"
//               }
//               onClick={() => onUpdateActiveLink("Products")}
//             >
//               Products
//             </Nav.Link>
//             <Nav.Link
//               href="/Brix"
//               className={
//                 activeLink === "Brix" ? "active navbar-link" : "navbar-link"
//               }
//               onClick={() => onUpdateActiveLink("Brix")}
//             >
//               BRIX🧱
//             </Nav.Link>

//             <Nav.Link
//               href="/Industries"
//               className={
//                 activeLink === "Industries"
//                   ? "active navbar-link"
//                   : "navbar-link"
//               }
//               onClick={() => onUpdateActiveLink("Industries")}
//             >
//               Industries
//             </Nav.Link>
//             <Nav.Link
//               href="/Company"
//               className={
//                 activeLink === "Company" ? "active navbar-link" : "navbar-link"
//               }
//               onClick={() => onUpdateActiveLink("Company")}
//             >
//               Company
//             </Nav.Link>
//             <Nav.Link
//               href="/News-&-Insights"
//               className={
//                 activeLink === "News & Insights"
//                   ? "active navbar-link"
//                   : "navbar-link"
//               }
//               onClick={() => onUpdateActiveLink("News & Insights")}
//             >
//               News & Insights
//             </Nav.Link>
//             <Nav.Link
//               href="/Services"
//               className={
//                 activeLink === "Services" ? "active navbar-link" : "navbar-link"
//               }
//               onClick={() => onUpdateActiveLink("Services")}
//             >
//               Services
//             </Nav.Link>
//             <Nav.Link
//               href="/Careers"
//               className={
//                 activeLink === "Careers" ? "active navbar-link" : "navbar-link"
//               }
//               onClick={() => onUpdateActiveLink("Careers")}
//             >
//               Careers
//             </Nav.Link>
//           </Nav>
//           <span className="navbar-text">
//             <HashLink to="/Contact">
//               <button className="vvd">
//                 <span>Get a Quote</span>
//               </button>
//             </HashLink>
//           </span>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// };

























import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../assets/img/logo3.png";
import { HashLink } from "react-router-hash-link";
import "./NavBar.css";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showMenu, setShowMenu] = useState(false); // Toggle for mobile menu

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    const handleResize = () => setIsMobile(window.innerWidth < 768);

    window.addEventListener("scroll", onScroll);
    window.addEventListener("resize", handleResize);

    handleResize(); // Check initial screen size

    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
    setShowMenu(false); // Close menu when an option is selected
  };

  return (
    <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
      <Container>
        {/* Logo */}
        <Navbar.Brand href="/">
          <img src={logo} alt="PEMS Digital" />
        </Navbar.Brand>

        {/* Mobile Menu Toggle */}
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setShowMenu(!showMenu)}
        >
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>

        {/* Mobile Menu */}
        {isMobile && showMenu && (
          <div
            className="mobile-menu"
            style={{
              position: "absolute",
              top: "100%",
              right: "0", // Align to the right
              width: "247px",
              height: "462px",
              backgroundColor: "#11222C",
              color: "white",
              zIndex: 1000,
              padding: "20px",
              borderRadius: "0 0 8px 8px", // Rounded corners
              filter: "drop-shadow(-4px 4px 8px rgba(0, 0, 0, 0.30))", // Drop shadow
            }}
          >
            {/* Language and Button */}
            <div
              className="flex justify-between items-center mb-4"
              style={{ paddingBottom: "16px", borderBottom: "1px solid #1A1A1A" }}
            >
              <span style={{ fontSize: "14px", color: "#ffffff" }}>EN, IN</span>
              <button
                className="get-quote-btn"
                style={{
                  backgroundColor: "#007BFF",
                  color: "white",
                  border: "none",
                  padding: "8px 16px",
                  borderRadius: "20px",
                  fontSize: "14px",
                }}
              >
                Get a Quote
              </button>
            </div>

            {/* Navigation Links */}
            <Nav className="flex flex-col">
              <Nav.Link
                href="/"
                className={activeLink === "Home" ? "active navbar-link" : "navbar-link"}
                onClick={() => onUpdateActiveLink("Home")}
                style={{
                  color: "white",
                  padding: "12px 0",
                  borderBottom: "1px solid #1A1A1A",
                  fontSize: "16px",
                }}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="/Products"
                className={
                  activeLink === "Products" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("Products")}
                style={{
                  color: "white",
                  padding: "12px 0",
                  borderBottom: "1px solid #1A1A1A",
                  fontSize: "16px",
                }}
              >
                Products
              </Nav.Link>
              <Nav.Link
                href="/Services"
                className={
                  activeLink === "Services" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("Services")}
                style={{
                  color: "white",
                  padding: "12px 0",
                  borderBottom: "1px solid #1A1A1A",
                  fontSize: "16px",
                }}
              >
                Services
              </Nav.Link>
              <Nav.Link
                href="/Brix"
                className={
                  activeLink === "Brix" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("Brix")}
                style={{
                  color: "white",
                  padding: "12px 0",
                  borderBottom: "1px solid #1A1A1A",
                  fontSize: "16px",
                }}
              >
                BRIX🧱
              </Nav.Link>
              <Nav.Link
                href="/Industries"
                className={
                  activeLink === "Industries" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("Industries")}
                style={{
                  color: "white",
                  padding: "12px 0",
                  borderBottom: "1px solid #1A1A1A",
                  fontSize: "16px",
                }}
              >
                Industries
              </Nav.Link>
              <Nav.Link
                href="/News-&-Insights"
                className={
                  activeLink === "News & Insights"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("News & Insights")}
                style={{
                  color: "white",
                  padding: "12px 0",
                  borderBottom: "1px solid #1A1A1A",
                  fontSize: "16px",
                }}
              >
                News & Insights
              </Nav.Link>
              <Nav.Link
                href="/Company"
                className={
                  activeLink === "Company" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("Company")}
                style={{
                  color: "white",
                  padding: "12px 0",
                  borderBottom: "1px solid #1A1A1A",
                  fontSize: "16px",
                }}
              >
                Company
              </Nav.Link>
              <Nav.Link
                href="/Contact-Us"
                className={
                  activeLink === "Contact Us" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("Contact Us")}
                style={{
                  color: "white",
                  padding: "12px 0",
                  fontSize: "16px",
                  whiteSpace: "normal", // Ensure proper wrapping for long text
                }}
              >
                Contact Us
              </Nav.Link>
            </Nav>
          </div>
        )}

        {/* Desktop Navigation */}
        {!isMobile && (
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                href="/"
                className={activeLink === "Home" ? "active navbar-link" : "navbar-link"}
                onClick={() => onUpdateActiveLink("Home")}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="/Products"
                className={
                  activeLink === "Products" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("Products")}
              >
                Products
              </Nav.Link>
              <Nav.Link
                href="/Services"
                className={
                  activeLink === "Services" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("Services")}
              >
                Services
              </Nav.Link>
              <Nav.Link
                href="/Brix"
                className={activeLink === "Brix" ? "active navbar-link" : "navbar-link"}
                onClick={() => onUpdateActiveLink("Brix")}
              >
                BRIX🧱
              </Nav.Link>
              <Nav.Link
                href="/Industries"
                className={
                  activeLink === "Industries" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("Industries")}
              >
                Industries
              </Nav.Link>
              <Nav.Link
                href="/News-&-Insights"
                className={
                  activeLink === "News & Insights"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("News & Insights")}
              >
                News & Insights
              </Nav.Link>
              <Nav.Link
                href="/Company"
                className={
                  activeLink === "Company" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("Company")}
              >
                Company
              </Nav.Link>
            </Nav>
            <span className="navbar-text">
              <HashLink to="/Contact">
                <button className="vvd">
                  <span>Get a Quote</span>
                </button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        )}
      </Container>
    </Navbar>
  );
};
