import React, { useEffect, useState } from "react";
import img2 from "../../assets/PNG/Photo1.png";
import img1 from "../../assets/PNG/Photo2.png";
import img3 from "../../assets/PNG/Photo3.png";
import circle from "../../assets/svg/Landing page Circle.svg";
import smallcircle from "../../assets/svg/Landingsmallercircle.svg";
import Arrow from "../../assets/svg/Arrow 1.svg";

// Custom hook to detect if it's mobile
const useMobileView = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check on mount
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

const App = () => {
  const [currentImage, setCurrentImage] = useState(img1);
  const isMobile = useMobileView();

  useEffect(() => {
    const images = [img1, img2, img3];
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      currentIndex = (currentIndex + 1) % images.length;
      setCurrentImage(images[currentIndex]);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const elements = document.querySelectorAll(".movable");
      elements.forEach((element) => {
        const shiftValue = element.getAttribute("data-shift") || 1;
        const moveX = (e.clientX * shiftValue) / 100;
        const moveY = (e.clientY * shiftValue) / 100;
        element.style.transform = `translate(${moveX}px, ${moveY}px)`;
      });
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div style={{ fontFamily: "Roboto" }}>
      <div className="relative min-h-screen overflow-hidden">
        {/* Background Image */}
        <div
          className="absolute inset-0 bg-cover bg-center animate-zoom-in transition-all duration-1000"
          style={{
            backgroundImage: `url(${currentImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>

        {/* Conditional Rendering Based on Device */}
        {!isMobile ? (
          <div className="relative z-10 min-h-screen text-white flex items-center justify-center">
            {/* Desktop Content */}
            <div className="absolute top-10 left-[-300px] w-full h-full flex items-center justify-center">
              <div className="relative inline-block mr-8">
                <img
                  src={circle}
                  alt="Circle"
                  className="movable absolute top-[-50px] left-[-50px] w-[200px] h-[200px]"
                  data-shift="1.2"
                />
                <div
                  className="movable text-white text-[48px] font-semibold font-['Roboto'] break-words relative z-10"
                  data-shift="3"
                >
                  <div>
                    <span
                      style={{
                        color: "white",
                        fontSize: "64px",
                        fontFamily: "Roboto",
                        fontWeight: 600,
                        wordWrap: "break-word",
                      }}
                    >
                      Welcome to
                      <br />
                    </span>
                    <span
                      style={{
                        fontSize: "64px",
                        fontFamily: "Roboto",
                        fontWeight: 600,
                        wordWrap: "break-word",
                        background: "linear-gradient(90deg, #68B24C, #07ABDD)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        display: "inline-block",
                      }}
                    >
                      PEMS Digital
                    </span>
                    <div
                      style={{
                        width: "100%",
                        color: "white",
                        fontSize: "19px",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        wordWrap: "break-word",
                      }}
                    >
                      Your Premier Digital Transformation Partner
                    </div>
                  </div>
                </div>
                <img
                  src={smallcircle}
                  className="movable w-8 h-8 ml-96"
                  data-shift="1.4"
                />
              </div>
            </div>

            <div className="absolute bottom-10 right-12 z-10">
              <p className="movable max-w-md text-gray-400" data-shift="1">
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    left: "-500px",
                    top: "-250px",
                  }}
                >
                  <div
                    style={{
                      width: "425px",
                      height: "296px",
                      left: "0px",
                      top: "0px",
                      position: "absolute",
                      opacity: 0.75,
                      background: "#0B0B0B",
                      borderRadius: "10px",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "345px",
                      height: "216px",
                      left: "40px",
                      top: "40px",
                      position: "absolute",
                    }}
                  >
                    <div
                      style={{
                        width: "134px",
                        height: "32px",
                        paddingLeft: "18px",
                        paddingRight: "18px",
                        paddingTop: "6px",
                        paddingBottom: "6px",
                        left: "0px",
                        top: "184px",
                        position: "absolute",
                        background: "white",
                        borderRadius: "45px",
                        overflow: "hidden",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "12px",
                        display: "inline-flex",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          color: "#2B3E48",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                          fontWeight: 600,
                          lineHeight: "20px",
                          wordWrap: "break-word",
                        }}
                      >
                        Learn More &rarr;
                      </div>
                    </div>
                    <div
                      style={{
                        width: "345px",
                        left: "0px",
                        top: "0px",
                        position: "absolute",
                        color: "white",
                        fontSize: "16px",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        lineHeight: "24px",
                        wordWrap: "break-word",
                      }}
                    >
                      At the forefront of driving business transformation
                      through innovative digital solutions, our expertise in
                      change management and digital transformation ensures that
                      your organization not only adapts to the evolving digital
                      landscape but thrives within it.
                    </div>
                  </div>
                </div>
              </p>
              <button
                className="movable mt-4 px-4 py-2 rounded-full flex items-center"
                data-shift="2"
              >
                <span className="ml-2"></span>
              </button>
            </div>
            <div className="absolute top-1/2 right-10  transform -translate-y-1/2 rotate-90 text-gray-400">
              <p
                className="movable"
                style={{
                  color: "white",
                  fontSize: "24px",
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "400",
                  textTransform: "uppercase",
                  wordWrap: "break-word",
                }}
                data-shift="1.5"
              >
                GROW YOUR BUSINESS
                <img
                  src={Arrow}
                  style={{
                    width: "32px",
                    height: "32px",
                    transform: "rotate(-90deg)",
                    marginBottom: "10px",
                  }}
                />
              </p>
            </div>
          </div>
        ) : (
          <div className="relative z-10 min-h-screen text-white flex items-center justify-center">
            {/* Mobile Content */}
            <div className="absolute top-10 left-5 w-full h-full flex flex-col items-center justify-center text-center">
              <div className="relative inline-block mb-8">
                <img
                  src={circle}
                  alt="Circle"
                  className="movable absolute top-[-25px] left-[-25px] w-[150px] h-[150px]"
                  data-shift="1.2"
                />
                <div
                  className="movable text-white text-[36px] font-semibold font-['Roboto'] break-words relative z-10"
                  data-shift="3"
                >
                  <div>
                    <span
                      style={{
                        color: "white",
                        fontSize: "48px",
                        fontFamily: "Roboto",
                        fontWeight: 600,
                        wordWrap: "break-word",
                      }}
                    >
                      Welcome to
                      <br />
                    </span>
                    <span
                      style={{
                        fontSize: "48px",
                        fontFamily: "Roboto",
                        fontWeight: 600,
                        wordWrap: "break-word",
                        background: "linear-gradient(90deg, #68B24C, #07ABDD)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        display: "inline-block",
                      }}
                    >
                      PEMS Digital
                    </span>
                    <div
                      style={{
                        width: "100%",
                        color: "white",
                        fontSize: "16px",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        wordWrap: "break-word",
                      }}
                    >
                      Your Premier Digital Transformation Partner
                    </div>
                  </div>
                </div>
                <img
                  src={smallcircle}
                  className="movable w-6 h-6 ml-48"
                  data-shift="1.4"
                />
              </div>

              <div className="relative z-10 p-4">
                <div
                  style={{
                    width: "300px",
                    height: "200px",
                    background: "#0B0B0B",
                    opacity: 0.75,
                    borderRadius: "10px",
                    padding: "16px",
                  }}
                >
                  <div
                    style={{
                      color: "white",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                      fontWeight: 400,
                      lineHeight: "20px",
                      wordWrap: "break-word",
                    }}
                  >
                    At the forefront of driving business transformation through
                    innovative digital solutions, our expertise in change
                    management and digital transformation ensures that your
                    organization not only adapts to the evolving digital
                    landscape but thrives within it.
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <style jsx global>{`
        @layer utilities {
          @keyframes zoom-in {
            0% {
              transform: scale(1);
            }
            100% {
              transform: scale(1.1);
            }
          }
          .animate-zoom-in {
            animation: zoom-in 5s ease-in-out infinite alternate;
          }
          .background-transition {
            transition: background-image 1s ease-in-out;
          }
        }
      `}</style>
    </div>
  );
};

export default App;




// import React, { useEffect, useState } from "react";
// import img2 from "../../assets/PNG/Photo1.png";
// import img1 from "../../assets/PNG/Photo2.png";
// import img3 from "../../assets/PNG/Photo3.png";
// import circle from "../../assets/svg/Landing page Circle.svg";
// import smallcircle from "../../assets/svg/Landingsmallercircle.svg";
// import Arrow from "../../assets/svg/Arrow 1.svg";

// // Custom hook to detect if it's mobile
// const useMobileView = () => {
//   const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };

//     handleResize(); // Check on mount
//     window.addEventListener("resize", handleResize);

//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   return isMobile;
// };

// const App = () => {
//   const [currentImage, setCurrentImage] = useState(img1);
//   const isMobile = useMobileView();

//   useEffect(() => {
//     const images = [img1, img2, img3];
//     let currentIndex = 0;

//     const intervalId = setInterval(() => {
//       currentIndex = (currentIndex + 1) % images.length;
//       setCurrentImage(images[currentIndex]);
//     }, 5000);

//     return () => clearInterval(intervalId);
//   }, []);

//   useEffect(() => {
//     const handleMouseMove = (e) => {
//       const elements = document.querySelectorAll(".movable");
//       elements.forEach((element) => {
//         const shiftValue = element.getAttribute("data-shift") || 1;
//         const moveX = (e.clientX * shiftValue) / 100;
//         const moveY = (e.clientY * shiftValue) / 100;
//         element.style.transform = `translate(${moveX}px, ${moveY}px)`;
//       });
//     };

//     document.addEventListener("mousemove", handleMouseMove);

//     return () => {
//       document.removeEventListener("mousemove", handleMouseMove);
//     };
//   }, []);

//   return (
//     <div style={{ fontFamily: "Roboto" }}>
//       <div className="relative min-h-screen overflow-hidden">
//         {/* Background Image */}
//         <div
//           className="absolute inset-0 bg-cover bg-center animate-zoom-in transition-all duration-1000"
//           style={{
//             backgroundImage: `url(${currentImage})`,
//             backgroundSize: "cover",
//             backgroundPosition: "center",
//           }}
//         ></div>

//         {/* Conditional Rendering Based on Device */}
//         {isMobile ? (
//           <div className="relative z-10 min-h-screen text-white flex items-center justify-center flex-col px-4">
//             {/* Mobile Content */}
//             <div className="relative w-full text-center mb-8">
//               <img
//                 src={circle}
//                 alt="Circle"
//                 className="movable absolute top-[-40px] left-[-40px] w-[100px] h-[100px]"
//                 data-shift="1.2"
//               />
//               <h1
//                 style={{
//                   fontSize: "32px",
//                   fontWeight: 600,
//                   lineHeight: "40px",
//                   color: "white",
//                 }}
//               >
//                 Welcome to{" "}
//                 <span
//                   style={{
//                     background: "linear-gradient(90deg, #68B24C, #07ABDD)",
//                     WebkitBackgroundClip: "text",
//                     WebkitTextFillColor: "transparent",
//                   }}
//                 >
//                   PEMS Digital
//                 </span>
//               </h1>
//               <div
//                 style={{
//                   color: "white",
//                   fontSize: "16px",
//                   fontWeight: 400,
//                   lineHeight: "24px",
//                   marginTop: "8px",
//                 }}
//               >
//                 Your Premier Digital Transformation Partner
//               </div>
//             </div>

//             {/* Mobile Button */}
//             <div className="relative z-10 mt-8">
//               <button
//                 className="px-6 py-2 bg-white text-black rounded-full"
//                 style={{
//                   fontWeight: 600,
//                   fontSize: "16px",
//                   textTransform: "uppercase",
//                 }}
//               >
//                 Learn More
//               </button>
//             </div>
//             <div className="absolute bottom-10 left-5 right-5 z-10">
//               <p
//                 className="movable text-white text-left"
//                 style={{
//                   fontSize: "14px",
//                   fontFamily: "Roboto",
//                   fontWeight: 400,
//                   lineHeight: "20px",
//                   width: "100%",
//                   margin: "0 auto",
//                 }}
//                 data-shift="1"
//               >
//                 At the forefront of driving business transformation through
//                 innovative digital solutions, our expertise in change
//                 management and digital transformation ensures that your
//                 organization not only adapts to the evolving digital landscape
//                 but thrives within it.
//               </p>
//             </div>

//             {/* Mobile Circle */}
//             <div
//               className="absolute top-10 left-[-50px] z-10"
//               style={{
//                 width: "100px",
//                 height: "100px",
//                 borderRadius: "50%",
//                 border: "6px solid #68B24C",
//                 opacity: "0.2",
//               }}
//             ></div>
//           </div>
//         ) : (
//           <div className="relative z-10 min-h-screen text-white flex items-center justify-center">
//             {/* Desktop Content */}
//             <div className="absolute top-10 left-[-300px] w-full h-full flex items-center justify-center">
//               <div className="relative inline-block mr-8">
//                 <img
//                   src={circle}
//                   alt="Circle"
//                   className="movable absolute top-[-50px] left-[-50px] w-[200px] h-[200px]"
//                   data-shift="1.2"
//                 />
//                 <div
//                   className="movable text-white text-[48px] font-semibold font-['Roboto'] break-words relative z-10"
//                   data-shift="3"
//                 >
//                   <div>
//                     <span
//                       style={{
//                         color: "white",
//                         fontSize: "64px",
//                         fontFamily: "Roboto",
//                         fontWeight: 600,
//                         wordWrap: "break-word",
//                       }}
//                     >
//                       Welcome to
//                       <br />
//                     </span>
//                     <span
//                       style={{
//                         fontSize: "64px",
//                         fontFamily: "Roboto",
//                         fontWeight: 600,
//                         wordWrap: "break-word",
//                         background: "linear-gradient(90deg, #68B24C, #07ABDD)",
//                         WebkitBackgroundClip: "text",
//                         WebkitTextFillColor: "transparent",
//                         display: "inline-block",
//                       }}
//                     >
//                       PEMS Digital
//                     </span>
//                     <div
//                       style={{
//                         width: "100%",
//                         color: "white",
//                         fontSize: "19px",
//                         fontFamily: "Roboto",
//                         fontWeight: 400,
//                         wordWrap: "break-word",
//                       }}
//                     >
//                       Your Premier Digital Transformation Partner
//                     </div>
//                   </div>
//                 </div>
//                 <img
//                   src={smallcircle}
//                   className="movable w-8 h-8 ml-96"
//                   data-shift="1.4"
//                 />
//               </div>
//             </div>

//             {/* Desktop Content Footer */}
//             <div className="absolute bottom-10 right-12 z-10">
//               <p className="movable max-w-md text-gray-400" data-shift="1">
//                 <div
//                   style={{
//                     width: "100%",
//                     height: "100%",
//                     position: "relative",
//                     left: "-500px",
//                     top: "-250px",
//                   }}
//                 >
//                   <div
//                     style={{
//                       width: "425px",
//                       height: "296px",
//                       left: "0px",
//                       top: "0px",
//                       position: "absolute",
//                       opacity: 0.75,
//                       background: "#0B0B0B",
//                       borderRadius: "10px",
//                     }}
//                   ></div>
//                   <div
//                     style={{
//                       width: "345px",
//                       height: "216px",
//                       left: "40px",
//                       top: "40px",
//                       position: "absolute",
//                     }}
//                   >
//                     <div
//                       style={{
//                         width: "134px",
//                         height: "32px",
//                         paddingLeft: "18px",
//                         paddingRight: "18px",
//                         paddingTop: "6px",
//                         paddingBottom: "6px",
//                         left: "0px",
//                         top: "184px",
//                         position: "absolute",
//                         background: "white",
//                         borderRadius: "45px",
//                         overflow: "hidden",
//                         justifyContent: "flex-start",
//                         alignItems: "center",
//                         gap: "12px",
//                         display: "inline-flex",
//                       }}
//                     >
//                       <div
//                         style={{
//                           textAlign: "center",
//                           color: "#2B3E48",
//                           fontSize: "14px",
//                           fontFamily: "Roboto",
//                           fontWeight: 600,
//                           lineHeight: "20px",
//                           wordWrap: "break-word",
//                         }}
//                       >
//                         Learn More &rarr;
//                       </div>
//                     </div>
//                     <div
//                       style={{
//                         width: "345px",
//                         left: "0px",
//                         top: "0px",
//                         position: "absolute",
//                         color: "white",
//                         fontSize: "16px",
//                         fontFamily: "Roboto",
//                         fontWeight: 400,
//                         lineHeight: "24px",
//                         wordWrap: "break-word",
//                       }}
//                     >
//                       At the forefront of driving business transformation
//                       through innovative digital solutions, our expertise in
//                       change management and digital transformation ensures that
//                       your organization not only adapts to the evolving digital
//                       landscape but thrives within it.
//                     </div>
//                   </div>
//                 </div>
//               </p>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default App;

