// // import React, { useState, useEffect } from "react";
// // import { motion } from "framer-motion";
// // import WeHeardYouCard from "./WeHeardYouCard";

// // const cardData = [
// //   {
// //     id: 1,
// //     inputs: [
// //       {
// //         imageUrl: require("../../assets/PNG/Brix/avtar1.png"),
// //         text: "Handling documents manually in our projects has led to errors, miscommunication, and even data loss. The risk of misplacing important files has been stressful, and keeping track of compliance-related paperwork has been a challenge.",
// //       },
// //       {
// //         imageUrl: require("../../assets/PNG/Brix/logo.png"),
// //         text: "BRIX offers a secure solution for managing all project-related documents. Teams can ensure workflows and compliance are seamless. Clients can rest assured their data is secure, organized, and accessible.",
// //       },
// //     ],
// //   },
// //   {
// //     id: 2,
// //     inputs: [
// //       {
// //         imageUrl: require("../../assets/PNG/Brix/avtar2.png"),
// //         text: "Our team often experiences delays due to the difficulty of retrieving and sharing up-to-date information. When project documents aren’t easily accessible, it leads to miscommunication, poor decision-making, and increased project costs.",
// //       },
// //       {
// //         imageUrl: require("../../assets/PNG/Brix/logo.png"),
// //         text: "BRIX's system enhances collaboration with real-time document access and sharing. Whether in the office or on-site, teams can retrieve and update documents instantly, reducing delays, improving communication, and ultimately helping to cut costs.",
// //       },
// //     ],
// //   },
// //   {
// //     id: 3,
// //     inputs: [
// //       {
// //         imageUrl: require("../../assets/PNG/Brix/avtar2.png"),
// //         text: "Managing a large volume of construction documents like blueprints, contracts, and invoices has been a constant struggle. Disorganization and version control issues slow down progress, and it’s frustrating to lose time looking for the latest version of a file.",
// //       },
// //       {
// //         imageUrl: require("../../assets/PNG/Brix/logo.png"),
// //         text: "BRIX provides a system that centralizes all your documents, ensuring everything is securely stored, easily accessible, and up-to-date. Our version control feature ensures precision, while advanced search tools make file retrieval quick and efficient.",
// //       },
// //     ],
// //   },
// // ];

// // const WeHeardYou = () => {
// //   const [animationComplete, setAnimationComplete] = useState(false);

// //   return (
// //     <div className="relative flex flex-col items-center bg-transparent p-6 md:p-16 lg:p-24 ">
// //       <h1
// //         className="text-2xl md:text-2xl lg:text-[42px] font-bold leading-normal text-center mb-6 "
// //         style={{
// //           color: "var(--Teal-Light-Charcoal-Default, #1B2028)",
// //           fontFamily: "'Space Grotesk', sans-serif",
// //         }}
// //       >
// //         We Heard You!
// //       </h1>

// //       {/* Top Row */}
// //       <div className="flex flex-col md:flex-row gap-6 mb-[-50px] md:mb-[-100px]">
// //         {cardData.slice(0, 2).map((card, index) => (
// //           <motion.div
// //             key={card.id}
// //             className="w-full md:w-1/2 max-w-[630px] max-h-[484px] flex-shrink-0 rounded-[20px] border border-[#D9DEE7] bg-[#FFF] shadow-md"
// //             initial={!animationComplete ? { opacity: 0, x: index === 0 ? -200 : 200 } : {}}
// //             animate={!animationComplete ? { opacity: 1, x: 0 } : {}}
// //             transition={{ duration: 1.5 }}
// //             onAnimationComplete={() => setAnimationComplete(true)}
// //           >
// //             <WeHeardYouCard inputs={card.inputs} />
// //           </motion.div>
// //         ))}
// //       </div>

// //       <div className="h-20 md:h-40 mt-1"></div>


// //       {/* Bottom Row */}
// //       <motion.div
// //         key={cardData[2].id}
// //         className="w-full md:w-2/3 lg:w-1/2 max-w-[630px] max-h-[484px] flex-shrink-0 rounded-[20px] border border-[#D9DEE7] bg-[#FFF] shadow-md relative z-10 mt-6 md:mt-0"
// //         initial={!animationComplete ? { opacity: 0, y: 100 } : {}}
// //         animate={!animationComplete ? { opacity: 1, y: 0 } : {}}
// //         transition={{ duration: 1.5 }}
// //       >
// //         <WeHeardYouCard inputs={cardData[2].inputs} />
// //       </motion.div>
// //     </div>
// //   );
// // };

// // export default WeHeardYou;















// import React, { useState } from "react";
// import { motion } from "framer-motion";
// import WeHeardYouCard from "./WeHeardYouCard";

// const cardData = [
//   {
//     id: 1,
//     inputs: [
//       {
//         imageUrl: require("../../assets/PNG/Brix/avtar1.png"),
//         text: "Handling documents manually in our projects has led to errors, miscommunication, and even data loss. The risk of misplacing important files has been stressful, and keeping track of compliance-related paperwork has been a challenge.",
//       },
//       {
//         imageUrl: require("../../assets/PNG/Brix/logo.png"),
//         text: "BRIX offers a secure solution for managing all project-related documents. Teams can ensure workflows and compliance are seamless. Clients can rest assured their data is secure, organized, and accessible.",
//       },
//     ],
//   },
//   {
//     id: 2,
//     inputs: [
//       {
//         imageUrl: require("../../assets/PNG/Brix/avtar2.png"),
//         text: "Our team often experiences delays due to the difficulty of retrieving and sharing up-to-date information. When project documents aren’t easily accessible, it leads to miscommunication, poor decision-making, and increased project costs.",
//       },
//       {
//         imageUrl: require("../../assets/PNG/Brix/logo.png"),
//         text: "BRIX's system enhances collaboration with real-time document access and sharing. Whether in the office or on-site, teams can retrieve and update documents instantly, reducing delays, improving communication, and ultimately helping to cut costs.",
//       },
//     ],
//   },
//   {
//     id: 3,
//     inputs: [
//       {
//         imageUrl: require("../../assets/PNG/Brix/avtar2.png"),
//         text: "Managing a large volume of construction documents like blueprints, contracts, and invoices has been a constant struggle. Disorganization and version control issues slow down progress, and it’s frustrating to lose time looking for the latest version of a file.",
//       },
//       {
//         imageUrl: require("../../assets/PNG/Brix/logo.png"),
//         text: "BRIX provides a system that centralizes all your documents, ensuring everything is securely stored, easily accessible, and up-to-date. Our version control feature ensures precision, while advanced search tools make file retrieval quick and efficient.",
//       },
//     ],
//   },
// ];

// const WeHeardYou = () => {
//   const [animationComplete, setAnimationComplete] = useState(false);

//   return (
//     <div className="relative flex flex-col items-center bg-transparent px-4 py-6 sm:px-6 md:px-16 lg:px-24 mb-8">
//       {/* Heading */}
//       <h1
//         className="text-xl sm:text-2xl md:text-2xl  text-[28px] lg:text-[52px] font-bold text-center mt-8 mb-8"
//         style={{
//           color: "var(--Teal-Light-Charcoal-Default, #1B2028)",
//           fontFamily: "'Space Grotesk', sans-serif",
//         }}
//       >
//         We Heard You!
//       </h1>

//       {/* Top Row */}
//       <div className="flex flex-col md:flex-row gap-6 w-full justify-center items-center md:items-stretch">
//         {cardData.slice(0, 2).map((card, index) => (
//           <motion.div
//             key={card.id}
//             className="w-full sm:w-[90%] md:w-[45%] max-w-[630px] flex-shrink-0 rounded-[20px] border border-[#D9DEE7] bg-[#FFF] shadow-md"
//             initial={!animationComplete ? { opacity: 0, x: index === 0 ? -200 : 200 } : {}}
//             animate={!animationComplete ? { opacity: 1, x: 0 } : {}}
//             transition={{ duration: 1.5 }}
//             onAnimationComplete={() => setAnimationComplete(true)}
//           >
//             <WeHeardYouCard inputs={card.inputs} />
//           </motion.div>
//         ))}
//       </div>

//       {/* Spacer */}
//       <div className="h-8 sm:h-10 md:h-16 lg:h-20"></div>

//       {/* Bottom Row */}
//       <motion.div
//         key={cardData[2].id}
//         className="w-full sm:w-[90%] md:w-[60%] lg:w-[50%] max-w-[630px] flex-shrink-0 rounded-[20px] border border-[#D9DEE7] bg-[#FFF] shadow-md relative z-10"
//         initial={!animationComplete ? { opacity: 0, y: 100 } : {}}
//         animate={!animationComplete ? { opacity: 1, y: 0 } : {}}
//         transition={{ duration: 1.5 }}
//       >
//         <WeHeardYouCard inputs={cardData[2].inputs} />
//       </motion.div>
//     </div>
//   );
// };

// export default WeHeardYou;











import React, { useState } from "react";
import { motion } from "framer-motion";
import WeHeardYouCard from "./WeHeardYouCard";

const cardData = [
  {
    id: 1,
    inputs: [
      {
        imageUrl: require("../../assets/PNG/Brix/avtar1.png"),
        text: "Handling documents manually in our projects has led to errors, miscommunication, and even data loss. The risk of misplacing important files has been stressful, and keeping track of compliance-related paperwork has been a challenge.",
      },
      {
        imageUrl: require("../../assets/PNG/Brix/logo.png"),
        text: "BRIX offers a secure solution for managing all project-related documents. Teams can ensure workflows and compliance are seamless. Clients can rest assured their data is secure, organized, and accessible.",
      },
    ],
  },
  {
    id: 2,
    inputs: [
      {
        imageUrl: require("../../assets/PNG/Brix/avtar2.png"),
        text: "Our team often experiences delays due to the difficulty of retrieving and sharing up-to-date information. When project documents aren’t easily accessible, it leads to miscommunication, poor decision-making, and increased project costs.",
      },
      {
        imageUrl: require("../../assets/PNG/Brix/logo.png"),
        text: "BRIX's system enhances collaboration with real-time document access and sharing. Whether in the office or on-site, teams can retrieve and update documents instantly, reducing delays, improving communication, and ultimately helping to cut costs.",
      },
    ],
  },
  {
    id: 3,
    inputs: [
      {
        imageUrl: require("../../assets/PNG/Brix/avtar3.png"),
        text: "Managing a large volume of construction documents like blueprints, contracts, and invoices has been a constant struggle. Disorganization and version control issues slow down progress, and it’s frustrating to lose time looking for the latest version of a file.",
      },
      {
        imageUrl: require("../../assets/PNG/Brix/logo.png"),
        text: "BRIX provides a system that centralizes all your documents, ensuring everything is securely stored, easily accessible, and up-to-date. Our version control feature ensures precision, while advanced search tools make file retrieval quick and efficient.",
      },
    ],
  },
];

const WeHeardYou = () => {
  const [animationComplete, setAnimationComplete] = useState(false);

  return (
    <div className="relative flex flex-col items-center bg-transparent px-4 py-6 sm:px-8 md:px-16 lg:px-24 mb-8">
      {/* Heading */}
      <h1
        className="text-center text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold leading-tight mb-8"
        style={{
          color: "#1B2028",
          fontFamily: "'Space Grotesk', sans-serif",
        }}
      >
        We Heard You!
      </h1>

      {/* Top Row */}
      <div className="flex flex-col md:flex-row gap-6 w-full justify-center items-stretch">
        {cardData.slice(0, 2).map((card, index) => (
          <motion.div
            key={card.id}
            className="w-full sm:w-[90%] md:w-[45%] lg:w-[40%] max-w-[630px] flex-shrink-0 rounded-lg border border-gray-300 bg-white shadow-md"
            initial={!animationComplete ? { opacity: 0, x: index === 0 ? -200 : 200 } : {}}
            animate={!animationComplete ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.5 }}
            onAnimationComplete={() => setAnimationComplete(true)}
          >
            <WeHeardYouCard inputs={card.inputs} />
          </motion.div>
        ))}
      </div>

      {/* Spacer */}
      <div className="h-8 sm:h-10 md:h-16 lg:h-20"></div>

      {/* Bottom Row */}
      <motion.div
        key={cardData[2].id}
        className="w-full sm:w-[90%] md:w-[60%] lg:w-[45%] max-w-[630px] flex-shrink-0 rounded-lg border border-gray-300 bg-white shadow-md relative z-10"
        initial={!animationComplete ? { opacity: 0, y: 100 } : {}}
        animate={!animationComplete ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1.5 }}
      >
        <WeHeardYouCard inputs={cardData[2].inputs} />
      </motion.div>
    </div>
  );
};

export default WeHeardYou;


