// import React from "react";
// import { motion } from "framer-motion";
// import EfficiencyIcon from "../../assets/svg/clock-up-arrow.svg";
// import SecurityIcon from "../../assets/svg/shield-check.svg";
// import AccessibilityIcon from "../../assets/svg/people-network-partner.svg";
// import VersionControlIcon from "../../assets/svg/copy-alt 1.svg";

// const FeatureCards = () => {
//   const features = [
//     {
//       icon: <img src={EfficiencyIcon} alt="Efficiency Icon" className="w-8 h-8" />,
//       title: "EFFICIENCY",
//       content:
//         "Stay efficient with real-time access, version control, and seamless collaboration across all your construction teams.",
//     },
//     {
//       icon: <img src={SecurityIcon} alt="Security Icon" className="w-8 h-8" />,
//       title: "SECURITY",
//       content:
//         "Ensure your critical documents are protected from unauthorised access while maintaining full transparency and compliance.",
//     },
//     {
//       icon: <img src={AccessibilityIcon} alt="Accessibility Icon" className="w-8 h-8" />,
//       title: "ACCESSIBILITY",
//       content:
//         "Keep your team connected in real-time, ensuring critical files and updates are always within reach on-site or in the office.",
//     },
//     {
//       icon: <img src={VersionControlIcon} alt="Version Control Icon" className="w-8 h-8" />,
//       title: "VERSION CONTROL",
//       content:
//         "Keep track of document versions and changes effortlessly with our comprehensive and intuitive version control feature.",
//     },
//   ];

//   return (
//     <motion.div className="flex flex-wrap gap-4 sm:gap-6 justify-center px-4 sm:px-6 lg:px-12 pb-8 sm:pb-12 md:pb-16">
//       {features.map((feature, index) => (
//         <motion.div
//           key={index}
//           className="flex flex-col w-full sm:w-[290px] h-auto p-4 items-start gap-3 border rounded-lg border-teal-100 bg-white shadow-[0_0_8px_rgba(65,234,212,0.3)]"
//           initial={{ opacity: 0, y: 50 }} // Initial state: Hidden and shifted downward
//           animate={{ opacity: 1, y: 0 }} // Final state: Visible and in place
//           transition={{ duration: 0.6, delay: index * 0.2, ease: "easeOut" }} // Staggered animation
//         >
//           {/* Icon and Heading */}
//           <div className="flex items-center gap-3">
//             {feature.icon}
//             <h3 className="text-base sm:text-lg font-semibold text-[#2B3E48]">{feature.title}</h3>
//           </div>
//           {/* Content */}
//           <p className="text-sm text-[#768090] leading-snug">{feature.content}</p>
//         </motion.div>
//       ))}
//     </motion.div>
//   );
// };

// export default FeatureCards;








import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import EfficiencyIcon from "../../assets/svg/clock-up-arrow.svg";
import SecurityIcon from "../../assets/svg/shield-check.svg";
import AccessibilityIcon from "../../assets/svg/people-network-partner.svg";
import VersionControlIcon from "../../assets/svg/copy-alt 1.svg";

const FeatureCards = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const features = [
    {
      icon: <img src={EfficiencyIcon} alt="Efficiency Icon" className="w-10 h-10" />,
      title: "EFFICIENCY",
      content:
        "Stay efficient with real-time access, version control, and seamless collaboration across all your construction teams.",
    },
    {
      icon: <img src={SecurityIcon} alt="Security Icon" className="w-10 h-10" />,
      title: "SECURITY",
      content:
        "Ensure your critical documents are protected from unauthorised access while maintaining full transparency and compliance.",
    },
    {
      icon: <img src={AccessibilityIcon} alt="Accessibility Icon" className="w-10 h-10" />,
      title: "ACCESSIBILITY",
      content:
        "Keep your team connected in real-time, ensuring critical files and updates are always within reach on-site or in the office.",
    },
    {
      icon: <img src={VersionControlIcon} alt="Version Control Icon" className="w-10 h-10" />,
      title: "VERSION CONTROL",
      content:
        "Keep track of document versions and changes effortlessly with our comprehensive and intuitive version control feature.",
    },
  ];

  return (
    <motion.div
      className={`flex ${
        isMobile ? "flex-col items-center gap-6 px-4" : "flex-wrap gap-6 justify-center px-6 lg:px-12"
      } pb-8 sm:pb-12 md:pb-16`}
    >
      {features.map((feature, index) => (
        <motion.div
          key={index}
          className={`flex flex-col ${
            isMobile ? "w-full" : "w-full sm:w-[290px]"
          } h-auto p-6 items-start gap-4 border rounded-lg border-teal-100 bg-white shadow-[0_0_10px_rgba(65,234,212,0.3)]`}
          initial={{ opacity: 0, y: 50 }} // Initial state: Hidden and shifted downward
          animate={{ opacity: 1, y: 0 }} // Final state: Visible and in place
          transition={{ duration: 0.6, delay: index * 0.2, ease: "easeOut" }} // Staggered animation
        >
          {/* Icon and Heading */}
          <div className="flex items-center gap-4">
            {feature.icon}
            <h3 className="text-lg font-bold text-[#2B3E48]">{feature.title}</h3>
          </div>
          {/* Content */}
          <p className="text-sm text-[#768090] leading-relaxed">{feature.content}</p>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default FeatureCards;


