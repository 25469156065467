// import React, { useState, useEffect } from "react";
// import { motion } from "framer-motion";
// import desktop from "../../assets/PNG/Brix/Desktop.png";

// const Desktop = () => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   return (
//     <div
//       className={`relative flex flex-col ${
//         isMobile ? "p-4" : "items-center justify-center h-screen px-4 sm:px-8"
//       } bg-gradient-to-br from-[#E3F9F2] via-[#FFFFFF] to-[#FFEBE8] overflow-hidden`}
//     >
//       {/* Background Desktop Image */}
//       <img
//         src={desktop}
//         alt="Desktop Preview"
//         className={`rounded-lg ${
//           isMobile
//             ? "w-full max-w-[280px] h-auto mt-4"
//             : "w-full max-w-[776px] max-h-[453px]"
//         }`}
//       />

//       {/* Top-Center Bubble */}
//       <motion.div
//         className={`absolute ${
//           isMobile
//             ? "top-[5%] left-1/2 transform -translate-x-1/2"
//             : "top-[5%] left-1/2 transform -translate-x-1/2"
//         } bg-white text-[#768090] rounded-[30px_30px_30px_0px] border border-[#D1F8F0] p-3 sm:p-4 flex flex-col items-center justify-center ${
//           isMobile ? "w-[140px] h-[60px]" : "w-[200px] sm:w-[284px] h-[66px] sm:h-[86px]"
//         } drop-shadow-[0_0_8px_rgba(65,234,212,0.30)]`}
//         initial={{ y: 0 }}
//         animate={{ y: [-20, 20, -20] }}
//         transition={{
//           duration: 3,
//           repeat: Infinity,
//           repeatType: "reverse",
//         }}
//       >
//         <h1 className={`${isMobile ? "text-sm" : "text-base sm:text-xl"} font-bold text-[#1B2028]`}>4X</h1>
//         <p className={`${isMobile ? "text-[10px]" : "text-xs sm:text-sm"} text-center`}>
//           enhanced collaboration & communication
//         </p>
//       </motion.div>

//       {/* Right-Side Bubble */}
//       <motion.div
//         className={`absolute ${
//           isMobile
//             ? "top-[35%] right-[5%]"
//             : "top-[40%] sm:top-[45%] right-[10%]"
//         } bg-white text-[#768090] rounded-[30px_30px_30px_0px] border border-[#D1F8F0] p-3 sm:p-4 flex flex-col items-center justify-center ${
//           isMobile ? "w-[120px] h-[80px]" : "w-[180px] sm:w-[243px] h-[100px] sm:h-[121px]"
//         } drop-shadow-[0_0_8px_rgba(65,234,212,0.30)]`}
//         initial={{ x: 0 }}
//         animate={{ x: [0, 20, 0] }}
//         transition={{
//           duration: 3,
//           repeat: Infinity,
//           repeatType: "reverse",
//         }}
//       >
//         <h1 className={`${isMobile ? "text-sm" : "text-base sm:text-xl"} font-bold text-[#1B2028]`}>
//           Up to 30%
//         </h1>
//         <p className={`${isMobile ? "text-[10px]" : "text-xs sm:text-sm"} text-center`}>
//           improved deals & team efficiency
//         </p>
//       </motion.div>

//       {/* Left-Side Bubble */}
//       <motion.div
//         className={`absolute ${
//           isMobile
//             ? "top-[35%] left-[5%]"
//             : "top-[40%] sm:top-[45%] left-[10%]"
//         } bg-white text-[#768090] rounded-[30px_30px_0px_30px] border border-[#D1F8F0] p-3 sm:p-4 flex flex-col items-center justify-center ${
//           isMobile ? "w-[120px] h-[80px]" : "w-[180px] sm:w-[243px] h-[100px] sm:h-[121px]"
//         } drop-shadow-[0_0_8px_rgba(65,234,212,0.30)]`}
//         initial={{ x: 0 }}
//         animate={{ x: [0, -20, 0] }}
//         transition={{
//           duration: 3,
//           repeat: Infinity,
//           repeatType: "reverse",
//         }}
//       >
//         <h1 className={`${isMobile ? "text-sm" : "text-base sm:text-xl"} font-bold text-[#1B2028]`}>
//           Up to 50%
//         </h1>
//         <p className={`${isMobile ? "text-[10px]" : "text-xs sm:text-sm"} text-center`}>
//           reduction in document-related errors
//         </p>
//       </motion.div>
//     </div>
//   );
// };

// export default Desktop;














import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import desktop from "../../assets/PNG/Brix/Desktop.png";

const Desktop = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={`relative flex items-center justify-center ${
        isMobile ? "p-4" : "h-screen px-4 sm:px-8"
      } bg-gradient-to-br from-[#E3F9F2] via-[#FFFFFF] to-[#FFEBE8] overflow-hidden`}
    >
      {/* Background Desktop Image */}
      <img
        src={desktop}
        alt="Desktop Preview"
        className={`rounded-lg ${
          isMobile
            ? "w-full max-w-[320px] h-auto mt-8"
            : "w-full max-w-[776px] max-h-[453px]"
        }`}
      />

      {/* Top-Center Bubble */}
      <motion.div
        className={`absolute ${
          isMobile
            ? "top-[5%] left-[55%] transform -translate-x-1/2"
            : "top-[10%] left-[55%] transform -translate-x-1/2"
        } bg-white text-[#768090] rounded-[30px_30px_30px_0px] border border-[#D1F8F0] p-3 sm:p-4 flex flex-col items-center justify-center ${
          isMobile ? "w-[140px] h-[60px]" : "w-[200px] h-[86px]"
        } drop-shadow-[0_0_8px_rgba(65,234,212,0.30)]`}
        initial={{ y: 0 }}
        animate={{ y: [-10, 10, -10] }}
        transition={{
          duration: 3,
          repeat: Infinity,
          repeatType: "reverse",
        }}
      >
        <h1 className={`${isMobile ? "text-sm" : "text-base"} font-bold text-[#1B2028]`}>
          4X
        </h1>
        <p className={`${isMobile ? "text-[10px]" : "text-xs"} text-center`}>
          enhanced collaboration & communication
        </p>
      </motion.div>

      {/* Left Bubble */}
      <motion.div
        className={`absolute ${
          isMobile
            ? "top-[40%] left-[5%]"
            : "top-[45%] left-[16%]"
        } bg-white text-[#768090] rounded-[30px_30px_0px_30px] border border-[#D1F8F0] p-3 sm:p-4 flex flex-col items-center justify-center ${
          isMobile ? "w-[120px] h-[80px]" : "w-[180px] h-[100px]"
        } drop-shadow-[0_0_8px_rgba(65,234,212,0.30)]`}
        initial={{ x: 0 }}
        animate={{ x: [-10, 10, -10] }}
        transition={{
          duration: 3,
          repeat: Infinity,
          repeatType: "reverse",
        }}
      >
        <h1 className={`${isMobile ? "text-sm" : "text-base"} font-bold text-[#1B2028]`}>
          Up to 50%
        </h1>
        <p className={`${isMobile ? "text-[10px]" : "text-xs"} text-center`}>
          reduction in document-related errors
        </p>
      </motion.div>

      {/* Right Bubble */}
      <motion.div
        className={`absolute ${
          isMobile
            ? "top-[40%] right-[5%]"
            : "top-[45%] right-[16%]"
        } bg-white text-[#768090] rounded-[30px_30px_30px_0px] border border-[#D1F8F0] p-3 sm:p-4 flex flex-col items-center justify-center ${
          isMobile ? "w-[120px] h-[80px]" : "w-[180px] h-[100px]"
        } drop-shadow-[0_0_8px_rgba(65,234,212,0.30)]`}
        initial={{ x: 0 }}
        animate={{ x: [10, -10, 10] }}
        transition={{
          duration: 3,
          repeat: Infinity,
          repeatType: "reverse",
        }}
      >
        <h1 className={`${isMobile ? "text-sm" : "text-base"} font-bold text-[#1B2028]`}>
          Up to 30%
        </h1>
        <p className={`${isMobile ? "text-[10px]" : "text-xs"} text-center`}>
          improved deals & team efficiency
        </p>
      </motion.div>
    </div>
  );
};

export default Desktop;

