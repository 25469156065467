import React, { useState } from "react";
import { NavBar } from "./NavBar";
import Banner from "./Careerpage/Banner";
import Opportunity from "./Careerpage/Opportunity";
import EmployeePerks from "./Careerpage/EmployeePerks";
import JoinPems from "./Careerpage/JoinPems";
import Apply from "./Careerpage/Apply";
import Quote from "./Careerpage/Quote";
import FooterCareer from "./ui/Footer/FooterCareer";
import WhyWorkWithUs from "./Careerpage/whyworkwithus";
import { Helmet } from "react-helmet";
import Faq from "./Faq";
const CareersPage = () => {
  return (
    <div>
      <Helmet>
        <title>Careers - PEMS Digital</title>
        <link rel="canonical" href="https://www.pemsdigital.com/Careers" />
      </Helmet>
      <NavBar />
      <Banner />
      <JoinPems />
      <WhyWorkWithUs />
      <Opportunity />
      <EmployeePerks />
      <Quote />
      <Apply />
      <FooterCareer />
    </div>
  );
};

export default CareersPage;
