import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { PushpinOutlined, TeamOutlined, LockOutlined, UploadOutlined, PlusCircleOutlined } from '@ant-design/icons';
import DashboardImage from '../../assets/PNG/Brix/Dashboard.png';

const TopScreen = () => {
  const [isVisible, setIsVisible] = useState(true); // State to control visibility of TopScreen

  // Monitor scroll to hide/show TopScreen
  useEffect(() => {
    let lastScrollTop = 0; // Track the last scroll position

    const handleScroll = () => {
      const currentScroll = window.scrollY;

      if (currentScroll > lastScrollTop && currentScroll > 100) {
        // Scrolling down and beyond 100px
        setIsVisible(false);
      } else if (currentScroll < lastScrollTop) {
        // Scrolling up
        setIsVisible(true);
      }

      lastScrollTop = currentScroll;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Animation for showing and hiding the TopScreen
  const screenVariants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 1, ease: 'easeOut' },
    },
    hidden: {
      y: -100,
      opacity: 0,
      transition: { duration: 1, ease: 'easeIn' },
    },
  };

  // Entrance animation for elements on page load
  const entranceAnimation = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.5,
        ease: 'easeOut',
      },
    },
  };

  // Smooth scroll to subscription form
  const scrollToSubscribeForm = () => {
    const subscriptionForm = document.getElementById('subscription-form');
    if (subscriptionForm) {
      subscriptionForm.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <motion.div
      className="relative flex flex-col items-center overflow-hidden"
      style={{
        width: '100%',
        background: 'linear-gradient(to right, #E3F9F2 30%, #FFEBE8 90%)',
      }}
      initial="visible"
      animate={isVisible ? 'visible' : 'hidden'}
      variants={screenVariants}
    >
      {/* Main Content with Entrance Animation */}
      <motion.div
        className="text-center px-4 sm:px-8 lg:px-16"
        style={{ maxWidth: '1069px' }}
        initial="hidden"
        animate="visible"
        variants={entranceAnimation}
      >
        {/* Main Heading */}
        <h1 className="text-[28px] sm:text-[36px] lg:text-[48px] font-bold text-[#1B2028] font-['Inter'] leading-tight mt-12">
          Organise Better.<br />
          Access Faster.
        </h1>

        {/* Subtitle */}
        <p className="text-[12px] sm:text-[14px] lg:text-[16px] font-normal text-[#768090] font-['Roboto'] leading-normal mt-2">
          The Only One Document Management System That Your Business Needs!
        </p>

        {/* Button */}
        <button
          className="flex justify-center items-center gap-2 mt-6 mx-auto px-4 py-2 w-[200px] sm:w-[233px] bg-[#FF6F61] text-white text-[14px] sm:text-[16px] font-medium rounded-lg"
          onClick={scrollToSubscribeForm} // Smooth scroll to subscription form
        >
          Avail Early Benefits
          <span className="text-lg">↓</span>
        </button>

        {/* Dashboard Image */}
        <motion.img
          src={DashboardImage}
          alt="Dashboard Preview"
          className="mt-8 w-full max-w-[1069px] max-h-[400px] sm:max-h-[500px] lg:max-h-[700px]"
          initial="hidden"
          animate="visible"
          variants={entranceAnimation}
        />
      </motion.div>

      {/* Floating Icons */}
      <motion.div
        className="absolute top-[15%] left-[5%] w-[40px] h-[40px] sm:w-[50px] sm:h-[50px] lg:w-[60px] lg:h-[60px] flex justify-center items-center transform rotate-[30deg] rounded-lg border border-[#99EDDE] bg-white shadow-md"
        initial="hidden"
        animate="visible"
        variants={entranceAnimation}
      >
        <UploadOutlined className="text-[#41EAD4] text-xl sm:text-2xl" />
      </motion.div>
      <motion.div
        className="absolute top-[35%] left-[10%] w-[40px] h-[40px] sm:w-[50px] sm:h-[50px] lg:w-[60px] lg:h-[60px] flex justify-center items-center transform rotate-[30deg] rounded-lg border border-[#99EDDE] bg-white shadow-md"
        initial="hidden"
        animate="visible"
        variants={entranceAnimation}
      >
        <PlusCircleOutlined className="text-[#41EAD4] text-xl sm:text-2xl" />
      </motion.div>

      {/* Right Side Icons */}
      <motion.div
        className="absolute top-[5%] right-[8%] w-[40px] h-[40px] sm:w-[50px] sm:h-[50px] lg:w-[60px] lg:h-[60px] flex justify-center items-center transform rotate-[30deg] rounded-lg border border-[#99EDDE] bg-white shadow-md"
        initial="hidden"
        animate="visible"
        variants={entranceAnimation}
      >
        <LockOutlined className="text-[#41EAD4] text-xl sm:text-2xl" />
      </motion.div>
      <motion.div
        className="absolute top-[25%] right-[5%] w-[40px] h-[40px] sm:w-[50px] sm:h-[50px] lg:w-[60px] lg:h-[60px] flex justify-center items-center transform rotate-[30deg] rounded-lg border border-[#99EDDE] bg-white shadow-md"
        initial="hidden"
        animate="visible"
        variants={entranceAnimation}
      >
        <TeamOutlined className="text-[#41EAD4] text-xl sm:text-2xl" />
      </motion.div>
      <motion.div
        className="absolute top-[20%] right-[17%] w-[40px] h-[40px] sm:w-[50px] sm:h-[50px] lg:w-[60px] lg:h-[60px] flex justify-center items-center transform rotate-[30deg] rounded-lg border border-[#99EDDE] bg-white shadow-md"
        initial="hidden"
        animate="visible"
        variants={entranceAnimation}
      >
        <PushpinOutlined className="text-[#41EAD4] text-xl sm:text-2xl" />
      </motion.div>

      {/* Subscription Form */}
      <div id="subscription-form" className=""></div>
    </motion.div>
  );
};

export default TopScreen;
