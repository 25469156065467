import React, { useState, useEffect } from "react";
import SlideImage from "./SlideImage";
import Image1 from "../../assets/PNG/Brix/image1.png";
import Image2 from "../../assets/PNG/Brix/image 2.png";
import Image3 from "../../assets/PNG/Brix/image3.png";
import Image4 from "../../assets/PNG/Brix/image 4.png";
import Image5 from "../../assets/PNG/Brix/image 5.png";

const OurBenefits = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const benefitsData = [
    {
      heading: "Robust Access Control & Secure Onboarding",
      listItems: [
        "Seamless User Onboarding: A smooth, secure sign-up process welcomes new users, ensuring they’re quickly set up and ready to start collaborating.",
        "Role-Based Access: Distinct user roles, including Super Admin, Admin, and Regular User, enable controlled access and tailored responsibilities for each user type.",
        "Granular Permissions: Each role is assigned unique privileges, ensuring only the right individuals have access to administrative tools.",
        "Enhanced Security: The authentication flow on BRIX is designed to provide top-level security for all users, reinforcing data protection across the platform.",
      ],
      imageUrl: Image1,
    },
    {
      heading: "Security Vault",
      listItems: [
        "Confidential Document Protection: Store sensitive documents in the secure vault to keep them separate from regular files and ensure they’re accessible only to authorized users.",
        "Pin-Based Access: Accessing the vault requires a unique PIN, adding an extra layer of security for confidential documents.",
        "Timed Session Control: Vault access is granted for a limited time. Once the session expires, users must re-enter their PIN to maintain high security while working in the vault.",
        "Controlled Sharing within Vault: When sharing documents stored in the vault, recipients need a PIN to view the content, providing an additional safeguard for secure collaboration.",
      ],
      imageUrl: Image2,
    },
    {
      heading: "Task Management",
      listItems: [
        "Effortless Task Tracking: Set up and assign tasks with ease using BRIX’s intuitive interface, designed to be as intuitive as Google Tasks but more powerful and efficient.",
        "Flexible Task Editing: Keep tasks up-to-date by allowing team members to edit task details, promoting accuracy and adaptability within the workspace.",
        "Teamwide Task Assignment: Empower collaboration by enabling admins or team leads to assign tasks to other team members, fostering a more connected and productive environment.",
        "Prioritization & Filtering: Organize tasks to priority and filter by project, due date, or assignee, making it simple to stay organized and track task flow effectively.",
      ],
      imageUrl: Image3,
    },
    {
      heading: "Document Management with Version Control",
      listItems: [
        "Effortless Version Tracking: Every time a document is uploaded, it’s versioned, so you can easily track changes and access past versions.",
        "Comprehensive Change History: Keep a detailed history of document updates, complete with timestamps and uploader information, for full transparency.",
        "Flexible Access Control: Manage view and edit permissions separately for each document, allowing you to fine-tune access based on individual or team needs.",
        "Easy Sharing Options: Share documents with the specified individuals or teams, ensuring the right people have access.",
        "On-Demand Access to Older Versions: Quickly access and download previous versions to maintain continuity and easily review historical changes.",
      ],
      imageUrl: Image4,
    },
    {
      heading: "Team Management",
      listItems: [
        "Intuitive User Management: Easily add, remove, and manage users within teams to streamline document access and collaboration.",
        "Custom Roles & Permissions: Assign custom roles to team members to control access based on responsibilities, promoting a secure environment.",
        "Task Creation & Document Sharing: Teams can create tasks, upload, and share documents as a group, supporting organized project workflows and efficient team collaboration.",
        "Clear Team Organization: Organize team members by projects, departments, or other relevant groups to keep document access well-structured and aligned with your project needs.",
      ],
      imageUrl: Image5,
    },
  ];

  return (
    <div className="w-full min-h-screen bg-gradient-to-r from-[#E3F9F2] to-[#FFEBE8]">
      {/* Header */}
      <div
        className={`flex justify-center items-center w-full ${
          isMobile ? "h-[150px]" : "h-[226px]"
        }`}
      >
        <h1
          className={`text-[#1B2028] font-space-grotesk text-center ${
            isMobile ? "text-[28px]" : "text-[52px]"
          } font-[700]`}
        >
          Our Benefits
        </h1>

      </div>

      {/* Render all slides */}
      <div
        className={`flex flex-col ${
          isMobile ? "space-y-8 px-4" : "space-y-12 lg:space-y-16 px-6 lg:px-20"
        }`}
      >
        {benefitsData.map((data, index) => (
          <SlideImage
            key={index}
            data={data}
            isMobile={isMobile}
          />
        ))}
      </div>
    </div>
  );
};

export default OurBenefits;
