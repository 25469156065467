import React from "react";
import { motion } from "framer-motion";

const WeHeardYouCard = ({ inputs }) => {
  if (!inputs || !Array.isArray(inputs)) {
    console.error("Inputs prop is undefined or not an array in WeHeardYouCard.");
    return null;
  }

  return (
    <motion.div
      className="p-6 flex flex-col gap-6"
      whileHover={{
        rotate: 2, // Slight rotation
        transition: { duration: 0.3 }, // Smooth hover effect
      }}
    >
      {inputs.map((input, index) => (
        <div key={index} className="flex items-start gap-4">
          {/* Avatar or Logo */}
          <div
            className={`${
              index === 0
                ? "w-[52px] h-[52px] flex-shrink-0 rounded-[48px] bg-cover bg-center"
                : "w-[52px] h-[52px] flex-shrink-0 rounded-[46px] flex items-center justify-center p-[6px]  bg-white"
            }`}
            style={{
              backgroundImage: `url(${input.imageUrl})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          ></div>

          {/* Text Container with Border */}
          <div
            className={`flex justify-center items-center w-[422px] p-[20px] gap-[10px] rounded-[8px] ${
              index === 0
                ? "border border-[#99EDDE] bg-[#F8FEFD]" // Border for first input
                : "border border-[#FCB3A9] bg-[#FCF9F9]" // Border for second input
            }`}
          >
            <p className="text-sm text-[#1B2028] ">{input.text}</p>
          </div>
        </div>
      ))}
    </motion.div>
  );
};

export default WeHeardYouCard;
