// import React, { useState } from "react";
// import { Spin } from "antd";
// import axios from "axios";
// import ReactGA from "react-ga4";


// const Subscribe = () => {
//   const [email, setEmail] = useState("");
//   const [error, setError] = useState("");
//   const [success, setSuccess] = useState("");
//   const [isLoading, setIsLoading] = useState(false);

//   // Function to validate email format
//   const validateEmail = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email format validation
//     return emailRegex.test(email);
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     setIsLoading(true);
//     e.preventDefault();

//     if (!validateEmail(email)) {
//       setError("Please enter a valid email address.");
//       setSuccess("");
//       setIsLoading(false); // Stop loading on validation error
//       return;
//     }

//     setError("");
//     setSuccess("");

//     const data = JSON.stringify({ email, position: "top" });
//     const config = {
//       method: "post",
//       maxBodyLength: Infinity,
//       url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/brix/subscribe`,
//       headers: {
//         "Content-Type": "application/json",
//       },
//       data: data,
//     };

//     try {
//       const response = await axios.request(config); // Send API request
//       ReactGA.event({
//         category: "subscriptions",
//         action: "submit",
//         label: "brix-subscription-top",
//         value: email,
//         transport: "xhr",
//       });
//       setSuccess("Subscription successful!"); // Handle success case
//       setEmail(""); // Clear only the email input
//     } catch (err) {
//       if (err.response && err.response.status === 400) {
//         // Handle duplicate email error (409 Conflict)
//         setSuccess("We Already Have Your Email, Stay Tuned.");
//       } else {
//         // Handle general error case
//         setError("Please try again later.");
//       }
//     } finally {
//       setIsLoading(false); // Hide loader
//     }
//   };

//   return (
//     <div className="bg-[#11222C] flex flex-col items-center py-20 px-10 text-center">
//       {/* Heading */}
//       <h1 className="text-4xl font-bold text-[#FFFFFF] mb-4">
//         Secure. Efficient. Accessible.
//       </h1>

//       {/* Paragraph */}
//       <p className="text-lg text-[#F8F9FB] mb-8">
//         Managing construction documents has never been easier. Our solution is
//         designed to streamline workflows and enhance <br />
//         project efficiency.
//       </p>

//       {/* Subscription Form */}
//       <form
//         className="flex items-center justify-center w-full max-w-[600px] bg-white border border-gray-300 rounded-full overflow-hidden"
//         onSubmit={handleSubmit}
//       >
//         <input
//           type="email"
//           placeholder="Email"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           className="flex-grow px-4 py-2 text-black placeholder-gray-500 focus:outline-none"
//         />
//         <button
//           type="submit"
//           disabled={isLoading} // Disable button when loading
//           className={`px-8 py-3 font-semibold text-white bg-[#FF6F61] transition duration-300 flex items-center justify-center ${
//             isLoading ? "cursor-not-allowed" : ""
//           }`}
//         >
//           {isLoading ? <Spin size="small" /> : "Claim Free Access"}
//         </button>
//       </form>

//       {/* Success Message */}
//       {success && <p className="text-green-500 text-md font-light mt-4">{success}</p>}

//       {/* Error Message */}
//       {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
//     </div>
//   );
// };

// export default Subscribe;



import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import axios from "axios";
import ReactGA from "react-ga4";

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Function to validate email format
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Handle form submission
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      setSuccess("");
      setIsLoading(false);
      return;
    }

    setError("");
    setSuccess("");

    const data = JSON.stringify({ email, position: "top" });
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/brix/subscribe`,
      headers: { "Content-Type": "application/json" },
      data: data,
    };

    try {
      const response = await axios.request(config);
      ReactGA.event({
        category: "subscriptions",
        action: "submit",
        label: "brix-subscription-top",
        value: email,
        transport: "xhr",
      });
      setSuccess("Subscription successful!");
      setEmail("");
    } catch (err) {
      if (err.response && err.response.status === 400) {
        setSuccess("We Already Have Your Email, Stay Tuned.");
      } else {
        setError("Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`flex flex-col items-center ${
        isMobile ? "py-12 px-6" : "py-20 px-10"
      } text-center bg-[#11222C]`}
    >
      {/* Heading */}
      <h1
        className={`font-bold text-[#FFFFFF] ${
          isMobile ? "text-3xl mb-4" : "text-4xl mb-6"
        }`}
      >
        Secure. Efficient. Accessible.
      </h1>

      {/* Paragraph */}
      <p
        className={`text-[#F8F9FB] ${
          isMobile ? "text-sm mb-6" : "text-lg mb-8"
        }`}
      >
        Managing construction documents has never been easier. Our solution is
        designed to streamline workflows and enhance project efficiency.
      </p>

      {/* Subscription Form */}
      <form
          className="flex items-center justify-center w-full max-w-[400px] bg-white border border-gray-300 rounded-lg overflow-hidden"
          onSubmit={handleSubmit}
        >

          {/* Email Input */}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="flex-grow px-4 py-3 text-black placeholder-gray-500 focus:outline-none rounded-l-lg"
            style={{ height: "48px", minWidth: "0" }}
          />

          {/* Submit Button */}
          <button
            type="submit"
            disabled={isLoading}
            className={`flex items-center justify-center font-semibold text-white bg-[#FF6F61] transition duration-300 whitespace-nowrap rounded-r-lg ${
              isLoading ? "cursor-not-allowed" : ""
            }`}
            style={{
              height: "48px",
              width: "200px", // Fixed width for consistency
            }}
          >
            {isLoading ? <Spin size="small" /> : "Claim Free Access"}
          </button>
        </form>


      {/* Success Message */}
      {success && (
        <p className="text-green-500 text-sm font-light mt-4">{success}</p>
      )}

      {/* Error Message */}
      {error && <p className="text-red-500 text-xs mt-2">{error}</p>}
    </div>
  );
};

export default Subscribe;

