// import React, { useState } from "react";
// import Logo from "../../assets/PNG/Brix/Brix-Logo.png";
// import { Spin } from "antd";
// import axios from "axios";
// import ReactGA from "react-ga4";

// const Navbar = () => {
//   const [email, setEmail] = useState("");
//   const [error, setError] = useState("");
//   const [success, setSuccess] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [menuOpen, setMenuOpen] = useState(false);

//   // Validate email
//   const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

//   // Handle subscription
//   const handleSubscribe = async () => {
//     if (!validateEmail(email)) {
//       setError("Please enter a valid email address.");
//       setSuccess("");
//       return;
//     }

//     setIsLoading(true);
//     setError("");
//     setSuccess("");

//     const data = JSON.stringify({ email, position: "top" });
//     const config = {
//       method: "post",
//       url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/brix/subscribe`,
//       headers: {
//         "Content-Type": "application/json",
//       },
//       data: data,
//     };

//     try {
//       const response = await axios.request(config);

//       if (response.status === 201) {
//         ReactGA.event({
//           category: "subscriptions",
//           action: "submit",
//           label: "brix-subscription-top",
//           value: email,
//           transport: "xhr",
//         });

//         window.location.hash = "#thanks";

//         setSuccess("Thank you for subscribing 👍");
//         setEmail(""); // Clear email input
//       }
//     } catch (err) {
//       if (err.response?.status === 400) {
//         setSuccess("We Already Have Your Email, Stay Tuned.");
//       } else {
//         setError("Please try again later.");
//       }
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   // Smooth scrolling function
//   const handleScrollToSection = (id) => {
//     const section = document.getElementById(id);
//     if (section) {
//       section.scrollIntoView({ behavior: "smooth", block: "start" });
//     }
//   };

//   return (
//     <nav className="flex flex-wrap items-center justify-between w-full max-w-[1440px] mx-auto px-4 md:px-20 py-4 border-b border-[#D9DEE7] bg-white backdrop-blur-sm">
//       {/* Logo Section */}
//       <div className="flex items-center justify-between w-full md:w-auto">
//         <img
//           src={Logo}
//           alt="Logo"
//           className="w-[127.66px] h-[48px] object-cover"
//         />
//         <button
//           className="text-[#768090] text-xl focus:outline-none md:hidden"
//           onClick={() => setMenuOpen(!menuOpen)}
//         >
//           {menuOpen ? "✕" : "☰"}
//         </button>
//       </div>

//       {/* Menu Items */}
//       <div
//         className={`${
//           menuOpen ? "flex" : "hidden"
//         } flex-col md:flex md:flex-row flex-1 justify-center items-center gap-8 text-[#768090] font-semibold text-sm md:text-[14px] leading-normal w-full md:w-auto mt-4 md:mt-0`}
//       >
//         <span onClick={() => handleScrollToSection("KeyFeatures")}>
//           Features
//         </span>
//         <span onClick={() => handleScrollToSection("ArchitectsAndDesigners")}>
//           For Teams
//         </span>
//         <span onClick={() => handleScrollToSection("WeHeardYou")}>
//           Resources
//         </span>
//         <span onClick={() => handleScrollToSection("OurBenefits")}>
//           Our Benefits
//         </span>
//         <span>EN, IN</span>
//       </div>

//       {/* Subscribe Section */}
//       <div className="w-full md:w-auto flex flex-col md:flex-row items-center gap-4 mt-4 md:mt-0">
//         {/* Email Input and Button Form */}
//         <form
//   className="flex items-center justify-center w-full max-w-[550px] bg-white border border-gray-300 rounded-lg overflow-hidden"
//   onSubmit={(e) => {
//     e.preventDefault();
//     handleSubscribe();
//   }}
// >
//   <input
//     type="email"
//     placeholder="Email"
//     value={email}
//     onChange={(e) => setEmail(e.target.value)}
//     className="flex-grow px-4 py-3 text-black placeholder-gray-500 focus:outline-none rounded-l-lg"
//     style={{ height: "48px" }} // Match button height
//   />
//   <button
//     type="submit"
//     disabled={isLoading} // Disable button when loading
//     className={`w-full sm:w-[200px] px-3 font-semibold text-white bg-[#FF6F61] transition duration-300 flex items-center justify-center whitespace-nowrap rounded-r-lg ${
//       isLoading ? "cursor-not-allowed" : ""
//     }`}
//     style={{
//       height: "48px",
//       fontSize: "14px", // Ensure text is readable
//       maxWidth: "200px", // Prevent button from becoming too wide
//     }}
//   >
//     {isLoading ? <Spin size="small" /> : "Claim Free Access"}
//   </button>
// </form>

//         {/* Success and Error Messages */}
//         <div className="flex items-center w-full md:w-auto text-left">
//           {error && <p className="text-red-500 text-xs ml-2">{error}</p>}
//           {success && (
//             <p className="text-green-600 text-sm font-semibold ml-2">
//               {success}
//             </p>
//           )}
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;








import React, { useState } from "react";
import Logo from "../../assets/PNG/Brix/Brix-Logo.png";
import { Spin } from "antd";
import axios from "axios";
import ReactGA from "react-ga4";

const Navbar = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  // Validate email
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Handle subscription
  const handleSubscribe = async () => {
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      setSuccess("");
      return;
    }

    setIsLoading(true);
    setError("");
    setSuccess("");

    const data = JSON.stringify({ email, position: "top" });
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/brix/subscribe`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);

      if (response.status === 201) {
        ReactGA.event({
          category: "subscriptions",
          action: "submit",
          label: "brix-subscription-top",
          value: email,
          transport: "xhr",
        });

        window.location.hash = "#thanks";

        setSuccess("Thank you for subscribing 👍");
        setEmail(""); // Clear email input
      }
    } catch (err) {
      if (err.response?.status === 400) {
        setSuccess("We Already Have Your Email, Stay Tuned.");
      } else {
        setError("Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Smooth scrolling function
  const handleScrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <nav className="flex flex-wrap items-center justify-between w-full max-w-[1440px] mx-auto px-4 md:px-20 py-4 border-b border-[#D9DEE7] bg-white backdrop-blur-sm">
      {/* Logo Section */}
      <div className="flex items-center justify-between w-full md:w-auto">
        <img
          src={Logo}
          alt="Logo"
          className="w-[127.66px] h-[48px] object-cover"
        />
        <button
          className="text-[#768090] text-xl focus:outline-none md:hidden"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen ? "✕" : "☰"}
        </button>
      </div>

      {/* Menu Items */}
      <div
        className={`${
          menuOpen ? "flex" : "hidden"
        } flex-col md:flex md:flex-row flex-1 justify-center items-center gap-8 text-[#768090] font-semibold text-sm md:text-[14px] leading-normal w-full md:w-auto mt-4 md:mt-0`}
      >
        <span onClick={() => handleScrollToSection("KeyFeatures")}>
          Features
        </span>
        <span onClick={() => handleScrollToSection("ArchitectsAndDesigners")}>
          For Teams
        </span>
        <span onClick={() => handleScrollToSection("WeHeardYou")}>
          Resources
        </span>
        <span onClick={() => handleScrollToSection("OurBenefits")}>
          Our Benefits
        </span>
        <span>EN, IN</span>
      </div>

      {/* Subscribe Section */}
      <div className="w-full md:w-auto flex flex-col md:flex-row items-center gap-4 mt-4 md:mt-0">
        <form
          className="flex items-center justify-between w-full md:w-auto max-w-[550px] bg-white border border-gray-300 rounded-lg"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubscribe();
          }}
        >
          {/* Email Input */}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="flex-grow px-4 py-3 text-black placeholder-gray-500 focus:outline-none rounded-l-lg"
            style={{ height: "48px", minWidth: "0" }}
          />

          {/* Submit Button */}
          <button
            type="submit"
            disabled={isLoading}
            className={`flex items-center justify-center px-4 font-semibold text-white bg-[#FF6F61] transition duration-300 whitespace-nowrap rounded-r-lg ${
              isLoading ? "cursor-not-allowed" : ""
            }`}
            style={{
              height: "48px",
              width: "200px", // Fixed width for consistency
              fontSize: "14px",
            }}
          >
            {isLoading ? <Spin size="small" /> : "Claim Free Access"}
          </button>
        </form>

        {/* Success and Error Messages */}
        <div className="flex items-center w-full md:w-auto text-left">
          {error && <p className="text-red-500 text-xs ml-2">{error}</p>}
          {success && (
            <p className="text-green-600 text-sm font-semibold ml-2">
              {success}
            </p>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;


