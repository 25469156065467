import React from 'react';
import Navbar from '../Brix/Navbar';
import TopScreen from '../Brix/TopScreen';
import Subscribe from './Subscribe';
import KeyFeatures from './KeyFeatures';
import KeyFeaturescard from './KeyFeaturesCard';
import Desktop from './Desktop';
import OurBenefits from './OurBenifits';
import SlideImage from './SlideImage';
import WeHeardYou from './WeHeardYou';
import WeHeardYouCard from './WeHeardYouCard';
import ArchitectsAndDesigners from './ArchitectsAndDesigners';
import FAQ from './Faq';
import ContactUs from './ContactUs';
import Footer from './Footer';
import ProductDetails from './ProductDetails';
import DocumentManagement from './DocumentManagement';

const Main = () => {
  return (
    <div>
      <Navbar />
      <TopScreen />
      <Subscribe/>
      <div id="KeyFeatures">
        <KeyFeatures />
      </div>
      <KeyFeaturescard />
      <Desktop />
      <div id="OurBenefits">
        <OurBenefits />
      </div>
      <SlideImage />
      <div id="WeHeardYou">
        <WeHeardYou />
        <WeHeardYouCard />
      </div>

      <div id="ArchitectsAndDesigners">
      <ArchitectsAndDesigners />
      </div>



      <DocumentManagement />
      <FAQ />
      <ProductDetails />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Main;
