// import React from "react";

// const KeyFeaturesHeading = () => {
//   return (
//     <h1 className="text-[#1B2028] text-center font-space-grotesk text-[36px] sm:text-[44px] md:text-[52px] font-bold leading-tight my-6 sm:my-8 md:my-12">
//       Key Features
//     </h1>
//   );
// };

// export default KeyFeaturesHeading;









import React, { useState, useEffect } from "react";

const KeyFeaturesHeading = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <h1
      className={`text-[#1B2028] text-center font-space-grotesk ${
        isMobile
          ? "text-[28px] leading-normal my-6"
          : "text-[36px] sm:text-[44px] md:text-[52px] leading-tight my-6 sm:my-8 md:my-12"
      } font-bold`}
    >
      Key Features
    </h1>
  );
};

export default KeyFeaturesHeading;

