// import React, { useState } from "react";
// import axios from "axios";
// import { Spin } from "antd";
// import { motion } from "framer-motion";  // Import motion from Framer Motion

// const SlideImage = ({ data, isMobile }) => {
//   const [email, setEmail] = useState("");
//   const [error, setError] = useState("");
//   const [success, setSuccess] = useState("");
//   const [isLoading, setIsLoading] = useState(false);

//   // Validate email
//   const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

//   // Handle subscription API call
//   const handleSubscribe = async () => {
//     if (!validateEmail(email)) {
//       setError("Please enter a valid email address.");
//       setSuccess("");
//       return;
//     }

//     setIsLoading(true);
//     setError("");
//     setSuccess("");

//     const data = JSON.stringify({ email, position: "slide" });
//     const config = {
//       method: "post",
//       url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/brix/subscribe`,
//       headers: {
//         "Content-Type": "application/json",
//       },
//       data: data,
//     };

//     try {
//       const response = await axios.request(config);

//       if (response.status === 201) {
//         setSuccess("Thank you for subscribing 👍");
//         setEmail(""); // Clear email input
//       }
//     } catch (err) {
//       if (err.response?.status === 400) {
//         setSuccess("We Already Have Your Email, Stay Tuned.");
//       } else {
//         setError("Please try again later.");
//       }
//     } finally {
//       setEmail(""); // Clear email input
//       setIsLoading(false);
//     }
//   };

//   if (!data) {
//     return null;
//   }

//   return (
//     <div
//       className={`flex flex-col items-center w-full py-6 lg:py-12`}
//       style={{ maxWidth: "1200px", margin: "0 auto" }}
//     >
//       {/* Heading - Animated from down to up */}
//       <motion.div
//         initial={{ opacity: 0, y: 50 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 1.6 }} // Increased duration for slower animation
//         className={`flex flex-col items-start w-full lg:w-1/2 mb-2`}
//         style={{ textAlign: "left" }}
//       >
//         <h2
//           className={`text-[#1B2028] font-space-grotesk ${
//             isMobile ? "text-[24px]" : "text-[32px]"
//           } font-bold leading-normal`}
//           style={{ marginBottom: "20px" }}
//         >
//           {data.heading}
//         </h2>
//       </motion.div>

//       {/* Image - Animated from down to up */}
//       <motion.div
//         initial={{ opacity: 0, y: 50 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 1.6, delay: 0.3 }} // Increased duration and delay for slower animation
//         className={`${
//           isMobile ? "w-full px-4" : "w-full lg:w-1/2 flex justify-center items-center"
//         } mb-6`}
//       >
//         <div className="relative w-full max-w-[600px] bg-[#FCF9F9] border border-[#99EDDE] p-4 rounded-[20px] overflow-hidden">
//           <img
//             src={data.imageUrl}
//             alt={data.heading}
//             className="w-full h-auto object-cover rounded-lg"
//             style={{ borderRadius: "20px" }}
//           />
//         </div>
//       </motion.div>

//       {/* Content (Bullet Points) - Animated one by one */}
//       <motion.div
//         initial={{ opacity: 0, y: 50 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 1.6, delay: 0.6 }} // Increased duration and delay for slower animation
//         className={`flex flex-col items-start ${
//           isMobile ? "gap-8 px-4" : "gap-6 lg:gap-[20px] px-6 lg:px-4"
//         } w-full lg:w-1/2 mb-8`}
//       >
//         <ul
//   className={`${
//     isMobile ? "space-y-4 text-sm" : "space-y-4 lg:space-y-4 text-base"
//   } text-[#1B2028] leading-[1.8] lg:leading-[2] list-disc pl-6`} // Increased line height for both views, with desktop having a slightly larger line-height
// >
//   {data.listItems.map((item, index) => (
//     <motion.li
//       key={index}
//       initial={{ opacity: 0, y: 50 }}
//       animate={{ opacity: 1, y: 0 }}
//       transition={{ duration: 1.6, delay: index * 0.6 }}
//       className="leading-[1.8] lg:leading-[2]" // Mobile: 1.8, Desktop: 2
//     >
//       <span className="font-semibold">{item.split(":")[0]}:</span>{" "}
//       <span>{item.split(":")[1]}</span>
//     </motion.li>
//   ))}
// </ul>
//       </motion.div>

//       {/* Email Input and Button - Animated from down to up */}
//       <motion.div
//         initial={{ opacity: 0, y: 50 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 1.6, delay: data.listItems.length * 0.6 }} // Increased duration for slower animation
//         className="flex justify-center w-full mt-6"
//       >
//         {/* Email input with rounded corners and button attached directly */}
//         <div className="flex justify-center w-full max-w-[570px]">
//           <input
//             type="email"
//             placeholder="Enter your email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             className="w-[70%] h-[49px] px-4 border border-[#CFCFCF] rounded-l-md focus:outline-none"
//           />
// <button
//   onClick={handleSubscribe}
//   disabled={isLoading}
//   className={`min-w-[200px] h-[48px] px-4 text-white bg-[#FF6F61] border-l border-[#CFCFCF] rounded-r-md ${
//     isLoading ? "cursor-not-allowed" : ""
//   } whitespace-nowrap`}
// >
//   {isLoading ? <Spin size="small" /> : "Claim Free Access"}
// </button>

//         </div>
//       </motion.div>

//       {error && <p className="text-red-500 text-xs">{error}</p>}
//       {success && (
//         <p className="text-green-600 text-sm font-semibold m-3">{success}</p>
//       )}
//     </div>
//   );
// };

// export default SlideImage;
















import React, { useState } from "react";
import axios from "axios";
import { Spin } from "antd";
import { motion } from "framer-motion"; // Framer Motion for animations

const SlideImage = ({ data, isMobile }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Validate email
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Handle subscription API call
  const handleSubscribe = async () => {
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      setSuccess("");
      return;
    }

    setIsLoading(true);
    setError("");
    setSuccess("");

    const data = JSON.stringify({ email, position: "slide" });
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/brix/subscribe`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);

      if (response.status === 201) {
        setSuccess("Thank you for subscribing 👍");
        setEmail(""); // Clear email input
      }
    } catch (err) {
      if (err.response?.status === 400) {
        setSuccess("We Already Have Your Email, Stay Tuned.");
      } else {
        setError("Please try again later.");
      }
    } finally {
      setEmail(""); // Clear email input
      setIsLoading(false);
    }
  };

  if (!data) {
    return null;
  }

  // Animation variants for elements
  const animationVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div
      className="flex flex-col items-center w-full py-6 lg:py-12"
      style={{ maxWidth: "1200px", margin: "0 auto" }}
    >
      {/* Heading */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        variants={animationVariants}
        transition={{ duration: 0.8 }}
        className="flex flex-col items-start w-full lg:w-1/2 mb-2"
        style={{ textAlign: "left" }}
      >
        <h2
          className={`text-[#1B2028] font-space-grotesk ${
            isMobile ? "text-[24px]" : "text-[32px]"
          } font-bold leading-normal`}
          style={{ marginBottom: "20px" }}
        >
          {data.heading}
        </h2>
      </motion.div>

      {/* Image */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        variants={animationVariants}
        transition={{ duration: 0.8, delay: 0.2 }}
        className={`${
          isMobile ? "w-full px-4" : "w-full lg:w-1/2 flex justify-center items-center"
        } mb-6`}
      >
        <div className="relative w-full max-w-[600px] bg-[#FCF9F9] border border-[#99EDDE] p-4 rounded-[20px] overflow-hidden">
          <img
            src={data.imageUrl}
            alt={data.heading}
            className="w-full h-auto object-cover rounded-lg"
            style={{ borderRadius: "20px" }}
          />
        </div>
      </motion.div>

      {/* Content (Bullet Points) */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        variants={animationVariants}
        transition={{ duration: 0.8, delay: 0.4 }}
        className={`flex flex-col items-start ${
          isMobile ? "gap-8 px-4" : "gap-6 lg:gap-[20px] px-6 lg:px-4"
        } w-full lg:w-1/2 mb-8`}
      >
        <ul
          className={`${
            isMobile ? "space-y-4 text-sm" : "space-y-4 lg:space-y-4 text-base"
          } text-[#1B2028] leading-[1.8] lg:leading-[2] list-disc pl-6`}
        >
          {data.listItems.map((item, index) => (
            <motion.li
              key={index}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.3 }}
              variants={animationVariants}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              className="leading-[1.8] lg:leading-[2]"
            >
              <span className="font-semibold">{item.split(":")[0]}:</span>{" "}
              <span>{item.split(":")[1]}</span>
            </motion.li>
          ))}
        </ul>
      </motion.div>

      {/* Email Input and Button */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        variants={animationVariants}
        transition={{ duration: 0.8, delay: data.listItems.length * 0.2 }}
        className="flex justify-center w-full mt-6"
      >
        <div className="flex justify-center w-full max-w-[570px]">
          {/* Email Input */}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="flex-grow px-4 py-3 text-black placeholder-gray-500 focus:outline-none rounded-l-lg"
            style={{ height: "48px", minWidth: "0" }}
          />
          <button
            onClick={handleSubscribe}
            disabled={isLoading}
            className={`flex items-center justify-center px-4 font-semibold text-white bg-[#FF6F61] transition duration-300 whitespace-nowrap rounded-r-lg ${
              isLoading ? "cursor-not-allowed" : ""
            }`}
            style={{
              height: "48px",
              width: "200px", // Fixed width for consistency
              fontSize: "14px",
            }}
          >
            {isLoading ? <Spin size="small" /> : "Claim Free Access"}
          </button>
        </div>
      </motion.div>

      {error && <p className="text-red-500 text-xs">{error}</p>}
      {success && (
        <p className="text-green-600 text-sm font-semibold m-3">{success}</p>
      )}
    </div>
  );
};

export default SlideImage;
