import React, { useState, useEffect } from "react";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <footer
      className={`w-full py-2 flex items-center justify-center ${
        isMobile ? "px-4 bg-gray-100" : "px-12 bg-white"
      }`}
    >
      <p
        className={`${
          isMobile
            ? "text-xs text-center leading-tight"
            : "text-sm leading-normal"
        } text-[#11222C] font-roboto font-normal`}
      >
        PEMS Digital Technologies Pvt. Ltd. © Copyright. All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
