// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
// import SvgImage from '../../assets/PNG/FAQ.png';

// const FAQs = () => {
//   const [activeIndex, setActiveIndex] = useState(null);
//   const [faqs, setFaqs] = useState([]);
//   const [question, setQuestion] = useState('');
//   const [searchQuery, setSearchQuery] = useState('');

//   useEffect(() => {
//     const fetchFaqs = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_BACKEND_URL}/api/v1/faq?category=home`
//         );
//         console.log('Fetched FAQs:', response.data);
//         if (response.data && Array.isArray(response.data.data)) {
//           setFaqs(response.data.data);
//         } else {
//           console.error('Unexpected response format:', response.data);
//         }
//       } catch (error) {
//         console.error('Error fetching FAQs:', error);
//       }
//     };
//     fetchFaqs();
//   }, []);

//   const handleToggle = (index) => {
//     setActiveIndex(activeIndex === index ? null : index);
//   };

//   const handleQuestionSubmit = async () => {
//     if (!question.trim()) {
//       alert('Please enter a question before submitting.');
//       return;
//     }

//     try {
//       await axios.post(
//         `${process.env.REACT_APP_BACKEND_URL}/api/v1/activity/questionSubmit`,
//         { question }
//       );
//       console.log('Question submitted:', question);
//       setQuestion('');
//     } catch (error) {
//       console.error('Error submitting question:', error);
//     }
//   };

//   const filteredFaqs = faqs.filter((faq) =>
//     faq.question.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   return (
//     <div className="container mx-auto px-4 py-16 flex flex-col lg:flex-row">
//       <div className="lg:w-1/2 mb-8 lg:mb-0">
//         <img src={SvgImage} alt="FAQs Illustration" className="w-full h-auto" />
//       </div>
//       <div className="lg:w-1/2">
//         <h1 className="text-gray-800 text-4xl font-semibold leading-tight mb-6 text-center">
//           FAQs
//         </h1>
//         <div className="max-w-2xl mx-auto">
//           <div className="mb-4">
//             <input
//               type="text"
//               placeholder="Search a query"
//               value={searchQuery}
//               onChange={(e) => setSearchQuery(e.target.value)}
//               className="w-full px-4 py-2 border rounded shadow-sm"
//             />
//           </div>
//           {filteredFaqs.length > 0 ? (
//             filteredFaqs.map((faq, index) => (
//               <div key={faq._id || index} className="mb-4">
//                 <button
//                   onClick={() => handleToggle(index)}
//                   className="w-full text-left px-4 py-2 border rounded shadow-sm focus:outline-none flex justify-between items-center"
//                 >
//                   <span className="text-gray-700 text-base">
//                     {faq.question}
//                   </span>
//                   {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
//                 </button>
//                 {activeIndex === index && (
//                   <div className="text-gray-700 text-base px-4 py-2 border rounded shadow-sm mt-2">
//                     {faq.answer}
//                   </div>
//                 )}
//               </div>
//             ))
//           ) : (
//             <div className="text-center text-gray-700">No FAQs available</div>
//           )}
//           <div className="flex flex-col sm:flex-row justify-between items-center mt-6">
//             <div className="flex flex-1 mr-2 mb-4 sm:mb-0">
//               <input
//                 type="text"
//                 value={question}
//                 onChange={(e) => setQuestion(e.target.value)}
//                 placeholder="Ask a Question"
//                 className="w-full px-4 py-2 border rounded-l shadow-sm"
//               />
//               <button
//                 onClick={handleQuestionSubmit}
//                 className="px-4 py-2 bg-gray-800 text-white rounded-r"
//               >
//                 Submit
//               </button>
//             </div>
//             <div className="flex flex-wrap justify-center sm:justify-start gap-4">
//               <a href="/Contact" className="text-gray-700 text-sm underline">
//                 Contact Us
//               </a>
//               <a
//                 href="/Privacy-Policy"
//                 className="text-gray-700 text-sm underline"
//               >
//                 Privacy Policy
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FAQs;







import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import SvgImage from '../../assets/PNG/FAQ.png';

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const [question, setQuestion] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/faq?category=home`
        );
        if (response.data && Array.isArray(response.data.data)) {
          setFaqs(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching FAQs:', error);
      }
    };
    fetchFaqs();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleQuestionSubmit = async () => {
    if (!question.trim()) {
      alert('Please enter a question before submitting.');
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/activity/questionSubmit`,
        { question }
      );
      setQuestion('');
    } catch (error) {
      console.error('Error submitting question:', error);
    }
  };

  const filteredFaqs = faqs.filter((faq) =>
    faq.question.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container mx-auto px-4 py-16 flex flex-col lg:flex-row">
      <div className="lg:w-1/2 mb-8 lg:mb-0">
        <img
          src={SvgImage}
          alt="FAQs Illustration"
          className="w-full h-auto"
        />
      </div>
      <div className="lg:w-1/2">
        <h1 className="text-gray-800 text-4xl font-semibold leading-tight mb-6 text-center">
          FAQs
        </h1>
        <div className="max-w-2xl mx-auto">
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search a query"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full px-4 py-2 border rounded shadow-sm"
            />
          </div>
          {filteredFaqs.length > 0 ? (
            filteredFaqs.map((faq, index) => (
              <div key={faq._id || index} className="mb-4">
                <button
                  onClick={() => handleToggle(index)}
                  className="w-full text-left px-4 py-2 border rounded shadow-sm focus:outline-none flex justify-between items-center"
                >
                  <span className="text-gray-700 text-base">
                    {faq.question}
                  </span>
                  {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                </button>
                {activeIndex === index && (
                  <div className="text-gray-700 text-base px-4 py-2 border rounded shadow-sm mt-2">
                    {faq.answer}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="text-center text-gray-700">No FAQs available</div>
          )}
          <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} justify-between items-center mt-6`}>
            <div className="flex flex-1 mr-2 mb-4 sm:mb-0">
              <input
                type="text"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder="Ask a Question"
                className="w-full px-4 py-2 border rounded-l shadow-sm"
              />
              <button
                onClick={handleQuestionSubmit}
                className="px-4 py-2 bg-gray-800 text-white rounded-r"
              >
                Submit
              </button>
            </div>
            <div className="flex flex-wrap justify-center sm:justify-start gap-4">
              <a href="/Contact" className="text-gray-700 text-sm underline">
                Contact Us
              </a>
              <a
                href="/Privacy-Policy"
                className="text-gray-700 text-sm underline"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;

