import React, { useState, useEffect } from "react";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";

const ResponsiveContactSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="w-full min-h-[207px] bg-[#007768] text-white px-4 py-8 sm:px-6 lg:px-12">
      {isMobile ? (
        // Mobile Layout
        <div className="flex flex-col">
          {/* Contact Details */}
          <div className="flex flex-col justify-center mb-6">
            <h2 className="text-[#FFFFFF] font-roboto text-[16px] font-semibold leading-[24px]">
              To know more about BRIX:
            </h2>
            <p className="text-[#FFFFFF] font-roboto text-[14px] font-medium leading-normal mt-2">
              Contact us:
            </p>
            <p className="text-[#FFFFFF] font-roboto text-[14px] font-medium leading-normal">
              akhil@pemsdigital.com
            </p>
            <p className="text-[#FFFFFF] font-roboto text-[14px] font-medium leading-normal">
              +91 9446220857
            </p>
          </div>

          {/* Follow Us Section */}
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-2 mb-4">
              <span className="bg-[#FF6F61] w-3 h-3 rounded-full"></span>
              <h2 className="text-[#FFFFFF] font-roboto text-[14px] font-semibold leading-[18px]">
                Follow us
              </h2>
            </div>
            <div className="flex space-x-4">
              <a
                href="https://www.facebook.com/profile.php?id=61561715193680"
                target="_blank"
                rel="noopener noreferrer"
                className="w-8 h-8 flex justify-center items-center bg-white text-[#007768] rounded-lg hover:bg-gray-200 transition"
                aria-label="Facebook"
              >
                <FaFacebookF size={16} />
              </a>
              <a
                href="https://www.linkedin.com/company/pems-digital-technologies-pvt-ltd/"
                target="_blank"
                rel="noopener noreferrer"
                className="w-8 h-8 flex justify-center items-center bg-white text-[#007768] rounded-lg hover:bg-gray-200 transition"
                aria-label="LinkedIn"
              >
                <FaLinkedinIn size={16} />
              </a>
              <a
                href="https://www.instagram.com/pems_digital/"
                target="_blank"
                rel="noopener noreferrer"
                className="w-8 h-8 flex justify-center items-center bg-white text-[#007768] rounded-lg hover:bg-gray-200 transition"
                aria-label="Instagram"
              >
                <FaInstagram size={16} />
              </a>
            </div>
          </div>
        </div>
      ) : (
        // Desktop Layout
        <div className="flex flex-row">
          {/* Left Side: Contact Details */}
          <div className="w-1/2 flex flex-col justify-center">
            <h2 className="text-[#FFFFFF] font-roboto text-[18px] font-semibold leading-[28px]">
              To know more about BRIX:
            </h2>
            <p className="text-[#FFFFFF] font-roboto text-[16px] font-medium leading-normal mt-2">
              Contact us:
            </p>
            <p className="text-[#FFFFFF] font-roboto text-[16px] font-medium leading-normal">
              akhil@pemsdigital.com
            </p>
            <p className="text-[#FFFFFF] font-roboto text-[16px] font-medium leading-normal">
              +91 9446220857
            </p>
          </div>

          {/* Right Side: Social Media */}
          <div className="w-1/2 flex flex-col justify-center items-end">
            <div className="flex items-center space-x-2 mb-6">
              <span className="bg-[#FF6F61] w-4 h-4 rounded-full"></span>
              <h2 className="text-[#FFFFFF] font-roboto text-[16px] font-semibold leading-[20px]">
                Follow us
              </h2>
            </div>
            <div className="flex space-x-6">
              <a
                href="https://www.facebook.com/profile.php?id=61561715193680"
                target="_blank"
                rel="noopener noreferrer"
                className="w-[32px] h-[32px] flex justify-center items-center bg-white text-[#007768] rounded-lg hover:bg-gray-200 transition"
                aria-label="Facebook"
              >
                <FaFacebookF size={18} />
              </a>
              <a
                href="https://www.linkedin.com/company/pems-digital-technologies-pvt-ltd/"
                target="_blank"
                rel="noopener noreferrer"
                className="w-[32px] h-[32px] flex justify-center items-center bg-white text-[#007768] rounded-lg hover:bg-gray-200 transition"
                aria-label="LinkedIn"
              >
                <FaLinkedinIn size={18} />
              </a>
              <a
                href="https://www.instagram.com/pems_digital/"
                target="_blank"
                rel="noopener noreferrer"
                className="w-[32px] h-[32px] flex justify-center items-center bg-white text-[#007768] rounded-lg hover:bg-gray-200 transition"
                aria-label="Instagram"
              >
                <FaInstagram size={18} />
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResponsiveContactSection;
